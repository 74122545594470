// components/DynamicPopover.js

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import { memberInfo } from '../../redux/Slices/memberSlice';
import PopOver from './PopOver';
import ImagePlaceholder from './ImagePlaceholder';

const MemberImage = ({ members, size }) => {
    const dispatch = useDispatch();
    const [popoverState, setPopoverState] = useState({ isVisible: false, contentId: null });

    const handleMemberInfo = (e, contentId, member) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };

        if (popoverState.isVisible && popoverState.contentId === contentId) {
            // If the popover is already visible for the same contentId, hide it
            setPopoverState({ isVisible: false, contentId: null });
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
        } else {
            // Show the popover for the new contentId
            setPopoverState({ isVisible: true, contentId });
            dispatch(togglePopover({ contentId, position, target }));
            dispatch(memberInfo({ member }));
        }
    };

    return (
        <div className='members-info'>
            {
                members?.map((member) => (
                    member.profilePicture ? (
                        <span
                            className='avatar'
                            key={member._id}
                            onClick={(e) => handleMemberInfo(e, 'memberInfo', member)}
                        >
                            <img style={{ width: size, height: size }} src={member.profilePicture} alt={member.name} />
                        </span>
                    ) : (
                        <span
                            className='avatar'
                            key={member._id}
                            onClick={(e) => handleMemberInfo(e, 'memberInfo', member)}
                        >
                            <ImagePlaceholder size={size} text={member.username} />
                        </span>
                    )
                ))
            }
        </div>
    );
};

export default MemberImage;
