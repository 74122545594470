import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

export const fetchWorkspaces = createAsyncThunk(
    'workspaces/fetchWorkspaces',
    async (_, { getState }) => {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(config.API_URI + '/api/workspaces/me', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch workspaces');
        }

        const data = await response.json();
        return data;
    }
);

const workspaceSlice = createSlice({
    name: 'workspaces',
    initialState: {
        workspaces: [],
        status: 'idle',
        error: null
    },
    reducers: {
        // You can add other synchronous reducers here if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkspaces.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWorkspaces.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.workspaces = action.payload;
            })
            .addCase(fetchWorkspaces.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default workspaceSlice.reducer;
