// DynamicPopover.js
import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hidePopover } from '../../redux/Slices/popoverSlice';
import useOutsideClick from '../../hooks/useOutsideClick';
import { getContentById } from '../../redux/ContentRegistry/popover';
import usePopoverPositionUpdater from '../../hooks/usePopoverPositionUpdater';
import './Popover.scss';

const PopOver = () => {
    const { isVisible, contentId, position, target } = useSelector((state) => state.popover);
    const dispatch = useDispatch();
    const popoverRef = useRef();

    useOutsideClick(popoverRef, () => dispatch(hidePopover()));
    usePopoverPositionUpdater(target);

    if (!isVisible) return null;

    const content = getContentById(contentId);

    return (
        <div className="zoobbe-popover" style={{ top: position.top + 5, left: position.left }} ref={popoverRef}>
            <div className="zoobbe-popover__header">
                <button className="zoobbe-popover__close-btn" onClick={() => { dispatch(hidePopover()) }}>
                    <span className="material-icons icon-close"> close</span>
                </button>
            </div>
            <div className="zoobbe-popover__content">
                {content}
            </div>
        </div>
    );
};

export default PopOver;
