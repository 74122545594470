import React from 'react'; 
import './scss/index.scss';

const AddActionListButton = ({onClick}) => {
    return (
        <div className="action-list-button-container">
            <button className="add-list-button" onClick={onClick}>
                <span className="plus-icon">+</span>
                Add another list
            </button>
        </div>
    );
}

export default AddActionListButton;
