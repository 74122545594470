import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toSlug } from '../../utils/helpers';

import { openModal } from '../../redux/Slices/modalSlice';

import CreateWorkspace from './CreateWorkspace';

import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { config } from '../../config';


import './scss/workspace.scss';


import './scss/Boards.scss';

const Cards = () => {

    const [boards, setBoards] = useState([]);
    const [showFormForWorkspace, setShowFormForWorkspace] = useState(null);
    const [boardTitle, setBoardTitle] = useState('');
    const { workspaces, status, error } = useSelector(state => state.workspaces);


    const dispatch = useDispatch();

    const handleCreateWorkspace = () => {
        dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
    };

    const handleCreateBoard = (defaultSelectedOption) => {
        dispatch(openModal({ modalType: 'CREATE_BOARD', modalData: { defaultSelectedOption } }));
    };


    // useEffect(() => {
    //     if (!workspaces) {
    //         dispatch(fetchWorkspaces());
    //     }
    // }, [dispatch, workspaces])

    useEffect(() => {
        dispatch(fetchWorkspaces());
    }, [dispatch]);



    const [openWorkspaces, setOpenWorkspaces] = useState({});

    useEffect(() => {
        const initialOpenWorkspaces = {};
        workspaces.slice(0, 2).forEach(workspace => {
            initialOpenWorkspaces[workspace.name] = true;
        });
        setOpenWorkspaces(initialOpenWorkspaces);
    }, [workspaces]);

    const toggleOptions = (workspaceName) => {
        setOpenWorkspaces(prevState => ({
            ...prevState,
            [workspaceName]: !prevState[workspaceName]
        }));
    };

    useEffect(() => {
        const fetchActionLists = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(config.API_URI  + `/api/boards`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    // setIsValid(false);
                    throw new Error('Failed to fetch boards');
                }

                const data = await response.json();
                setBoards(data);
            } catch (error) {
                console.error('Error fetching boards:', error);
            }
        };

        fetchActionLists();
    }, []);

    const handleAddButton = (workspaceId) => {
        setShowFormForWorkspace(prevWorkspaceId => prevWorkspaceId === workspaceId ? null : workspaceId);
    };

    const handleHideForm = () => {
        setShowFormForWorkspace(null);
    };

    return (
        <div className="zppbbe-workspace-wrapper">
            <div className="zoobbe-workspace-container">
                <aside className="zoobbe-sidebar">
                    <nav className="zoobbe-sidebar-nav">
                        <ul className='quick-links'>
                            <li className="zoobbe-nav-item"><Link to={'#'} >Boards</Link></li>
                            <li className="zoobbe-nav-item"><Link to={'#'} >Templates</Link></li>
                            <li className="zoobbe-nav-item"><Link to={'#'} >Home</Link></li>
                        </ul>
                        <div className="zoobbe-workspaces">
                            <h3 onClick={handleCreateWorkspace}>Workspaces
                                <span>+</span>
                            </h3>
                            {workspaces.map(workspace => (
                                <div key={workspace._id} className="zoobbe-workspace">
                                    <div className="zoobbe-workspace-header" onClick={() => toggleOptions(workspace.name)}>
                                        <div className="workspace-name">
                                            <span className="zoobbe-workspace-icon">{workspace.name ? workspace.name.charAt(0) : ''}</span>
                                            <span className="zoobbe-workspace-title">{workspace.name}</span>
                                        </div>
                                        <svg width="24" height="24" role="presentation" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11.2929 16.7071L4.22185 9.63606C3.83132 9.24554 3.83132 8.61237 4.22185 8.22185C4.61237 7.83133 5.24554 7.83133 5.63606 8.22185L12 14.5858L18.364 8.22185C18.7545 7.83132 19.3877 7.83132 19.7782 8.22185C20.1687 8.61237 20.1687 9.24554 19.7782 9.63606L12.7071 16.7071C12.3166 17.0977 11.6834 17.0977 11.2929 16.7071Z" fill="currentColor"></path>
                                        </svg>
                                    </div>
                                    {openWorkspaces[workspace.name] && (
                                        <ul className="zoobbe-workspace-options">
                                            <li><Link to={`/w/${workspace.shortName}/boards`}>Boards</Link></li>
                                            <li><Link to={`/w/${workspace.shortName}`}>Collections</Link></li>
                                            <li><Link to={`/w/${workspace.shortName}/highlights`}>Highlights</Link></li>
                                            <li><Link to={`/w/${workspace.shortName}/views`}>Views</Link></li>
                                            <li><Link to={`/w/${workspace.shortName}/members`}>Members</Link></li>
                                            <li><Link to={`/w/${workspace.shortName}/settings`}>Settings</Link></li>
                                        </ul>
                                    )}
                                </div>
                            ))}
                        </div>
                    </nav>
                </aside>

                <main className="zoobbe-main-content">
                    <div className="board-header">
                        <div className="board-icon">
                            <span className="icon-text">Z</span>
                        </div>
                        <div className="board-details">
                            <div className="board-name">
                                <span>Zoobbe</span>
                                <svg className="edit-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 0-.765-.638l-1.75 1.75a.5.5 0 1 0 .707.707l1.75-1.75a.5.5 0 0 0 .058-.069zM1 13.5V16h2.5l7.157-7.157-2.5-2.5L1 13.5zm3.207-1.207-2.5 2.5-.793-.793 2.5-2.5L4.207 12.5z" />
                                </svg>
                            </div>
                            <div className="board-visibility">
                                <svg className="lock-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8 1a4 4 0 0 0-4 4v3h8V5a4 4 0 0 0-4-4zm-3 5V5a3 3 0 1 1 6 0v1H5z" />
                                    <path d="M1 9v7a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9H1zm1 2h12v4H2v-4z" />
                                </svg>
                                <span>Private</span>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Cards;

