import React from "react";
import { Link } from "react-router-dom";
import { toSlug } from "../../utils/helpers";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/Slices/modalSlice";


const BoardSection = ({ boards, defaultSelectedOption }) => {

    const dispatch = useDispatch();

    const handleCreateBoard = (defaultSelectedOption) => {
        dispatch(openModal({ modalType: 'CREATE_BOARD', modalData: { defaultSelectedOption } }));
    };

    return (
        <div className="boards-page-board-section-list">
            {boards?.map(board => (
                <div className="boards-page-board-section-list-item" key={board._id}>
                    <Link className="board-tile" to={`/b/${board._id}/${toSlug(board.title)}`}>
                        {board.title}
                    </Link>
                    <div className="sUri8_UlrmJEfH u-clearfix" />
                </div>
            ))}

            <div className="board-tile mod-add" onClick={() => { handleCreateBoard(defaultSelectedOption) }}>
                <p><span>Create new board</span></p>
            </div>
        </div>
    )
}

export default BoardSection;