import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import { config } from '../../config';

import './scss/index.scss';

const UserProfile = () => {

    const [userInfo, setUserInfo] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);
    const [bio, setBio] = useState('');
    const [username, setUsername] = useState('');

    const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);


    const dispatch = useDispatch();

    useEffect(() => {
        if (user) {
            setUserInfo(user);
            setUsername(user.user.username);
            setBio(user.user.bio || '');
            setProfilePicture(user.user.profilePicture || '');
        }
    }, [dispatch, user])

    if (!user) {
        return;
    }


    const handleProfilePictureChange = (event) => {
        setProfilePicture(event.target.files[0]);
    };

    const handleBioChange = (event) => {
        setBio(event.target.value);
    };

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handleSave = async () => {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            return false;
        }

        const formData = new FormData();
        formData.append('profilePicture', profilePicture);
        formData.append('bio', bio);
        formData.append('username', username);

        try {
            const response = await fetch(config.API_URI + '/api/users/update', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                // throw new Error('Failed to update profile');
                dispatch(showToast({ message: 'Failed to update profile', type: 'error' }));

            }

            const data = await response.json();
            setUserInfo(data.user);
            dispatch(showToast({ message: 'Profile updated successfully.', type: 'success' }));

            return true;
        } catch (error) {
            dispatch(showToast({ message: 'Error updating profile:' + error, type: 'error' }));


            return false;
        }
    };

    if (!user) {
        return null;
    }

    return (
        <div className="profile-page">
            <div className="top-profile">
                <div className="account-section">
                    <img src={user.user.profilePicture || 'https://trello-members.s3.amazonaws.com/6332a834fcd2a90107bee76e/70a3e515372b3d7874102eb1962165d9/50.png'} alt="Profile Picture" className="profile-picture" />
                    <div className="account-info">
                        <p className="username">{user.user.username}</p>
                        <p className="email">{user.user.email}</p>
                    </div>
                </div>
            </div>
            <div className="bottom-profile-content">
                <nav className="sidebar">
                    <ul>
                        <li><Link to={`/u/${user.user.username}`}>Profile and visibility</Link></li>
                        <li><Link to={"/u/activity"}>Activity</Link></li>
                        <li><Link to={"/u/cards"}>Cards</Link></li>
                        <li><Link to={"/u/settings"}>Settings</Link></li>
                    </ul>
                </nav>
                <div className="content">
                    <h1>Manage your personal information</h1>
                    <p>This is an <strong><Link to={'#'}>Zoobbe</Link></strong> account. Edit your personal information and visibility settings through your Atlassian profile.</p>
                    <p>To learn more, view our <Link to="https://www.atlassian.com/legal/terms-of-service">Terms of Service</Link> or <Link to="https://www.atlassian.com/legal/privacy-policy">Privacy Policy</Link>.</p>
                    <div className="about-section">
                        <h2>About</h2>
                        <div className="form-group">
                            <label>Username</label>
                            <input type="text" value={username} onChange={handleUsernameChange} />
                        </div>

                        <div className="form-group">
                            <label>Bio</label>
                            <textarea value={bio} onChange={handleBioChange}></textarea>
                        </div>
                        <div className="form-group">
                            <label>Profile Picture</label>
                            <input type="file" onChange={handleProfilePictureChange} />

                            <div className="profile-picture">
                                <img src={profilePicture} alt={username} />
                            </div>
                        </div>
                        <button onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
