import React, { useState, useEffect, useRef } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Card from './Card';
import AddCardButton from './AddCardButton';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import { config } from '../../config';

const ActionList = ({ actionList, isOpen, onToggleOptions, onDelete }) => {
    const { _id, title, cards } = actionList;
    const [showForm, setShowForm] = useState(false);
    const [cardTitle, setCardTitle] = useState('');
    const [cardDescription, setCardDescription] = useState('');
    const [currentCards, setCurrentCards] = useState(cards);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);

    const cardTitleRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setCurrentCards(cards); // Update currentCards state when cards prop changes
    }, [cards]);

    const handleAddCard = async (e) => {
        e.preventDefault();
        console.log("New Card Title: ", cardTitle);

        try {
            const token = localStorage.getItem('accessToken');

            const response = await fetch(config.API_URI + '/api/cards', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    actionListId: _id,
                    title: cardTitle,
                    description: cardDescription,
                    users: [],
                }),
            });

            if (!response.ok) {
                dispatch(showToast({ message: 'Failed to add card ', type: 'error' }));
            }

            const data = await response.json();
            const newCard = data.card;
            setCurrentCards((prevCards) => [...prevCards, newCard]);
            setCardTitle('');
            setCardDescription('');
            setShowForm(false);
            dispatch(showToast({ message: 'Card added successfully!', type: 'success' }));
        } catch (error) {
            dispatch(showToast({ message: 'Error adding card: ' + error, type: 'error' }));
        }
    };

    const handleShowForm = () => {
        setShowForm(true);
    };

    const handleHideForm = () => {
        setShowForm(false);
    };

    const handleDeleteList = async () => {
        const token = localStorage.getItem('accessToken');
        setIsDeleting(true);

        try {
            const response = await fetch(config.API_URI + `/api/actionLists/delete/${_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete the list');
            }

            const data = await response.json();
            console.log('List deleted successfully:', data);

            onDelete(_id);
            dispatch(showToast({ message: 'List deleted successfully!', type: 'success' }));
        } catch (error) {
            dispatch(showToast({ message: 'Error deleting the list' + error, type: 'success' }));
        } finally {
            setIsDeleting(false);
        }
    };

    const handleArchivedList = async () => {
        const token = localStorage.getItem('accessToken');
        setIsArchiving(true);

        try {
            const response = await fetch(config.API_URI + `/api/actionLists/archive/${_id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to archive the list');
            }

            const data = await response.json();
            dispatch(showToast({ message: 'List archived successfully!', type: 'success' }));
        } catch (error) {
            dispatch(showToast({ message: 'Error archiving the list. ' + error, type: 'success' }));
        } finally {
            setIsArchiving(false);
        }
    };

    useEffect(() => {
        if (cardTitleRef.current) {
            console.log(cardTitleRef.current);
            cardTitleRef.current.focus();
            cardTitleRef.current.select();
        }
    }, [title, cards]);

    return (
        <div className="action-list-inner">
            <div className="list-header">
                <div className="list-title">
                    <h2>{title}</h2>
                    <div className="action-list-option">
                        <span className="action-list-option-icon" onClick={onToggleOptions}>
                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4m7 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4m9-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
                                    fill="currentColor"
                                />
                            </svg>
                        </span>
                        {isOpen && (
                            <div className="list-options">
                                <Link to="#" onClick={handleDeleteList} disabled={isDeleting}>
                                    {isDeleting ? 'Deleting...' : 'Delete'}
                                </Link>
                                <Link to="#" onClick={handleArchivedList} disabled={isArchiving}>
                                    {isArchiving ? 'Archiving...' : 'Archive'}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="action-list-container">
                <Droppable droppableId={_id} type="CARD">
                    {(provided) => (
                        <div
                            className="list-group"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            <div className="action-card-list">
                                {currentCards?.map((card, index) => (
                                    <Draggable key={card._id} draggableId={card._id} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Card {...card} />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                            <div className="add-new-card-form">
                                {showForm && (
                                    <form onSubmit={handleAddCard}>
                                        <textarea
                                            ref={cardTitleRef}
                                            name="add-new-card"
                                            placeholder="Enter card title"
                                            rows="1"
                                            value={cardTitle}
                                            onChange={(e) => setCardTitle(e.target.value)}
                                            required
                                        />
                                        <div className="footer-buttons">
                                            <button type="submit">Add Card</button>
                                            <button type="button" onClick={handleHideForm}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414z"
                                                        fill="#000"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                    </form>
                                )}
                                {!showForm && <AddCardButton onClick={handleShowForm} />}
                            </div>
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    );
};

export default ActionList;
