import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Provider } from 'react-redux';
import store from './redux/store';

import Header from './components/Header';
import Login from './components/Auth/Login';
import Signup from './components/Auth/Signup';
import Workspaces from './components/Workspaces';
import CardDetails from './components/Workspaces/CardDetails';
import UserProfile from './components/Profile/UserProfile';

import { getUser, refreshAccessToken, maskId } from './utils/helpers';
import { BoardProvider } from './context/BoardContext';

import './index.css';
import Board from './components/Workspaces/Board';
import NotFound from './components/400';
import CreateWorkspace from './components/Profile/CreateWorkspace';
import Workspace from './components/Profile/Workspace';
import Modal from './components/Global/ModalManager';
import Toast from './components/Global/Toast';

//Profile Component
import Boards from './components/Profile/Boards';
import Cards from './components/Profile/Cards';
import PopOver from './components/Global/PopOver';
import { fetchWorkspaces } from './redux/Slices/workspaceSlice';

// Dummy components for the routes
const Recent = () => <div>Recent Page</div>;
const Starred = () => <div>Starred Page</div>;
const Templates = () => <div>Templates Page</div>;
const Create = () => <div>Create Page</div>;

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isCardPopup, setIsCardPopup] = useState(false);
  const [userProfileBoardsLink, setUserProfileBoardsLink] = useState('');

  const dispatch = useDispatch();

  // const [isValid, setIsValid] = useState(true);

  const isCheckingLoginStatus = useRef(false); // Flag to ensure the function runs only once

  useEffect(() => {
    const checkLoginStatus = async () => {
      if (isCheckingLoginStatus.current) {
        return;
      }

      isCheckingLoginStatus.current = true;

      const data = await getUser();
      if (!data?.user?._id) {
        const refreshed = await refreshAccessToken();
        setLoggedIn(refreshed);
      } else {
        setLoggedIn(data.user._id);
        setUserProfileBoardsLink('/u/' + data.user.username + '/boards');
      }
      setLoading(false);
    };

    dispatch(fetchWorkspaces());

    checkLoginStatus();
  }, []);


  if (loading) {
    return <div>Loading...</div>; // You can replace this with a proper loading spinner
  }

  // console.log(maskId('66753d0237f1182683c6cfde'));
  // console.log(maskId('66753de637f1182683c6d019'));
  // console.log(maskId('66753e6137f1182683c6d029'));

  return (
    <div className="App" id=''>
      <Provider store={store}>
        <Router>
          {loggedIn && <Header />}
          {/* {loggedIn && isCardPopup && <Board />} */}
          <BoardProvider>
            <Routes>
              <Route path="/workspace" element={loggedIn ? <Workspace /> : <Navigate to="/login" />} />
              <Route path="/" element={loggedIn ? <Navigate to={userProfileBoardsLink} /> : <Navigate to="/login" />} />
              <Route path="/u/:userName" element={loggedIn ? <UserProfile /> : <Navigate to="/login" />} />
              <Route path="/u/:userName/boards" element={loggedIn ? <Workspace /> : <Navigate to="/login" />} />
              <Route path="/w/:workspaceSlug" element={loggedIn ? <Workspaces /> : <Navigate to="/login" />} />
              <Route path="/b/:boardId/:boardSlug" element={loggedIn ? <Board /> : <Navigate to="/login" />} />
              <Route path="/c/:cardId/:cardSlug" element={loggedIn ? <Board isCardPopup={true} /> : <Navigate to="/login" />} />

              <Route path="/w/:workspaceSlug/boards" element={loggedIn ? <Boards /> : <Navigate to="/login" />} />
              <Route path="/w/:workspaceSlug/cards" element={loggedIn ? <Cards /> : <Navigate to="/login" />} />

              <Route path="/login" element={!loggedIn ? <Login /> : <Navigate to="/u/:userName" />} />
              <Route path="/signup" element={!loggedIn ? <Signup /> : <Navigate to="/u/:userName" />} />
              {/* Catch-all route for 404 */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BoardProvider>

          <Modal />
          <Toast />
          <PopOver />

        </Router>
      </Provider>

    </div>
  );
}

export default App;
