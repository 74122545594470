import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { uploadAttachment } from "../../redux/Slices/attachmentSlice";

const Attachment = ({ onUpload }) => (
    <svg width="11" height="22" viewBox="0 0 11 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onUpload}>
        <path d="M9.5 5v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V4a2.5 2.5 0 1 1 5 0v10.5c0 .55-.45 1-1 1s-1-.45-1-1V5H3v9.5a2.5 2.5 0 0 0 5 0V4c0-2.21-1.79-4-4-4S0 1.79 0 4v12.5C0 19.54 2.46 22 5.5 22s5.5-2.46 5.5-5.5V5z" fill="#000" />
    </svg>
);

const EditorToolbar = ({ cardId }) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();

    const handleAttachmentClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const result = await dispatch(uploadAttachment({ file, cardId }));

            if (result.meta.requestStatus === 'fulfilled') {
                // Assuming payload is an array, get the last uploaded file
                const { name, url } = result.payload[result.payload.length - 1];

                if (window.quillRef && url) {
                    const quill = window.quillRef.getEditor();
                    const range = quill.getSelection();

                    console.log(range);
                    
                    if (range) {
                        quill.insertText(range?.index, name, "link", url);
                        quill.insertText(range?.index + name?.length, " ", "link", url); // Add a space after the link
                    }
                }
            }
        }
    };


    return (
        <div id="toolbar">
            <span className="ql-formats">
                <select className="ql-header" defaultValue="3">
                    <option value="1">Heading</option>
                    <option value="2">Subheading</option>
                    <option value="3">Normal</option>
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                <button className="ql-strike" />
            </span>
            <span className="ql-formats">
                <button className="ql-blockquote" />
            </span>
            <span className="ql-formats">
                <button className="ql-list" value="ordered" />
                <button className="ql-list" value="bullet" />
            </span>
            <span className="ql-formats">
                <button className="ql-link" />
                <button className="ql-image" />
                <button className="ql-code-block" />
            </span>
            <span className="ql-formats">
                <button className="ql-attachment" onClick={handleAttachmentClick}>
                    <Attachment />
                </button>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </span>
        </div>
    );
};

export default EditorToolbar;
