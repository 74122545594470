import React, { useRef, useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useOutsideClick from '../../hooks/useOutsideClick';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import { fetchCardMembers, addCardMember, removeCardMember } from '../../redux/Slices/cardMemberSlice';
import { config } from '../../config';
import './scss/MembersPopover.scss';

const MemberItem = memo(({ member, handleAction }) => (
    <div className="members-popover__member" onClick={(e) => handleAction(e, member)}>
        {member.profilePicture ? (
            <img src={member.profilePicture} alt={member.username} className="members-popover__avatar" />
        ) : (
            <ImagePlaceholder text={member.username} fontSize='14px' />
        )}
        <span>{member.username}</span>
    </div>
));

const MembersPopover = ({ cardId, onClose }) => {
    const { members: boardMembers } = useSelector((state) => state.boardMembers);
    const { membersByCard } = useSelector((state) => state.cardMembers);
    const initialCardMembers = membersByCard[cardId] || [];
    const dispatch = useDispatch();

    const [cardMembers, setCardMembers] = useState([]);
    const [availableBoardMembers, setAvailableBoardMembers] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const popoverRef = useRef();

    useOutsideClick(popoverRef, onClose);

    useEffect(() => {
        setCardMembers(initialCardMembers);
        setAvailableBoardMembers(boardMembers.filter(member => !initialCardMembers.some(cardMember => cardMember._id === member._id)));
        setIsLoaded(true);
    }, [initialCardMembers, boardMembers]);

    const handleAddMember = async (e, member) => {
        e.preventDefault();
        setError(null);
        setCardMembers(prevMembers => [...prevMembers, member]);
        setAvailableBoardMembers(prevMembers => prevMembers.filter(m => m._id !== member._id));

        try {
            await addMember({ cardId, memberId: member._id });
            dispatch(fetchCardMembers(cardId));
        } catch (error) {
            setError('Failed to add member.');
            setCardMembers(prevMembers => prevMembers.filter(m => m._id !== member._id));
            setAvailableBoardMembers(prevMembers => [...prevMembers, member]);
        }
    };

    const handleRemoveMember = async (e, member) => {
        e.preventDefault();
        setError(null);
        setCardMembers(prevMembers => prevMembers.filter(m => m._id !== member._id));
        setAvailableBoardMembers(prevMembers => [...prevMembers, member]);

        try {
            await removeMember({ cardId, memberId: member._id });
            dispatch(fetchCardMembers(cardId));
        } catch (error) {
            setError('Failed to remove member.');
            setCardMembers(prevMembers => [...prevMembers, member]);
            setAvailableBoardMembers(prevMembers => prevMembers.filter(m => m._id !== member._id));
        }
    };

    const addMember = async ({ cardId, memberId }) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/addMember`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ memberId })
            });

            if (!response.ok) {
                throw new Error('Failed to add member');
            }

            const data = await response.json();

            return { cardId, member: data }
            // return data;
        } catch (error) {
            console.error('Error adding member:', error);
            throw error;
        }
    };

    const removeMember = async ({ cardId, memberId }) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/removeMember`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ memberId })
            });

            if (!response.ok) {
                throw new Error('Failed to remove member');
            }

            const data = await response.json();

            return { cardId, memberId }
            // return data;
        } catch (error) {
            console.error('Error removing member:', error);
            throw error;
        }
    };


    return (
        <div className={`members-popover ${isLoaded ? 'loaded' : ''}`} ref={popoverRef}>
            <div className="members-popover__header">
                <h3>Members</h3>
                <button className="members-popover__close-btn" onClick={onClose}>&times;</button>
            </div>
            {error && <div className="members-popover__error">{error}</div>}
            <input
                type="text"
                className="members-popover__search"
                placeholder="Search members"
            />
            <div className={`members-popover__section`}>
                {cardMembers.length > 0 && <h4>Card members</h4>}
                {cardMembers.map(member => (
                    <MemberItem key={member._id} member={member} handleAction={handleRemoveMember} />
                ))}
            </div>
            <div className={`members-popover__section`}>
                {availableBoardMembers.length > 0 && <h4>Board members</h4>}
                {availableBoardMembers.map(member => (
                    <MemberItem key={member._id} member={member} handleAction={handleAddMember} />
                ))}
            </div>
        </div>
    );
};

export default MembersPopover;
