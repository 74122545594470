import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updatePopoverPosition } from '../redux/Slices/popoverSlice';
import { getScrollableAncestor } from '../utils/helpers';

const usePopoverPositionUpdater = (target) => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!target) return;

        const scrollableAncestor = getScrollableAncestor(target);

        const handleScroll = () => {
            const rect = target.getBoundingClientRect();
            const position = { top: rect.bottom, left: rect.left };
            dispatch(updatePopoverPosition(position));
        };

        scrollableAncestor.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            scrollableAncestor.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, [dispatch, target]);
};

export default usePopoverPositionUpdater;
