import React, { useEffect, useRef, useState, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactQuill, { Quill } from "react-quill";
import EditorToolbar from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./editor.scss";
import "../Workspaces/scss/editor.style.scss";

const Editor = ({ cardId, value, onChange, placeholder = "Write something awesome..." }, ref) => {
    const quillRef = useRef(null);
    const [showMention, setShowMention] = useState(false);
    const [mentionPosition, setMentionPosition] = useState({ top: 0, left: 0 });
    const [mentionQuery, setMentionQuery] = useState("");
    const [mentionSuggestions, setMentionSuggestions] = useState([]);
    const [cursorPosition, setCursorPosition] = useState(null);
    const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(0);

    const { membersByCard, loading, error } = useSelector((state) => state.cardMembers);
    const members = membersByCard[cardId] || [];

    console.log({ members });


    const modules = {
        toolbar: {
            container: "#toolbar",
        },
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true
        }
    };

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "align",
        "strike",
        "script",
        "blockquote",
        "background",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "color",
        "code-block"
    ];

    useEffect(() => {
        if (ref) {
            ref.current = quillRef.current;
        }
        window.quillRef = quillRef.current;

        const handleTextChange = () => {
            if (!(quillRef.current)) {
                return;
            }

            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();
            if (range) {
                setCursorPosition(range);
                const textBeforeCursor = quill.getText(0, range.index);
                const mentionIndex = textBeforeCursor.lastIndexOf('@');

                if (mentionIndex !== -1 && range.index > mentionIndex) {
                    const mentionText = textBeforeCursor.slice(mentionIndex + 1);
                    setMentionQuery(mentionText);
                    if (mentionText.length > -1 && !mentionText.includes(' ')) {
                        setShowMention(true);
                        const bounds = quill.getBounds(mentionIndex);
                        setMentionPosition({
                            top: bounds.top + bounds.height, // Position below the current line
                            left: bounds.left,
                        });
                        // Fetch mention suggestions based on mentionText
                        fetchMentionSuggestions(mentionText);
                    } else {
                        setShowMention(false);
                    }
                } else {
                    setShowMention(false);
                }
            }
        };


        const handleKeyDown = (e) => {
            if (showMention) {
                if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                    e.preventDefault(); // Prevent default arrow key behavior
                    if (e.key === "ArrowDown") {
                        setSelectedSuggestionIndex((prevIndex) =>
                            prevIndex === mentionSuggestions.length - 1 ? 0 : prevIndex + 1
                        );
                    } else if (e.key === "ArrowUp") {
                        setSelectedSuggestionIndex((prevIndex) =>
                            prevIndex === 0 ? mentionSuggestions.length - 1 : prevIndex - 1
                        );
                    }
                } else if (e.key === "Enter" && showMention) {
                    setShowMention(false);
                    handleMentionClick(mentionSuggestions[selectedSuggestionIndex]);
                }

                console.log(selectedSuggestionIndex);
            }
        };

        const quillInstance = quillRef.current ? quillRef.current.getEditor() : null;

        if (!quillInstance) {
            return;
        }

        console.log('Quill');

        quillInstance.on('text-change', handleTextChange);
        quillInstance.on('selection-change', handleTextChange);

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            if (quillInstance) {
                quillInstance.off('text-change', handleTextChange);
                quillInstance.off('selection-change', handleTextChange);
            }
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [ref, showMention, mentionSuggestions]);

    const fetchMentionSuggestions = (query) => {
        // Fetch suggestions based on the query
        // This is just a placeholder, replace with actual suggestion fetching logic
        const suggestions = members?.filter(user => user.name.toLowerCase().includes(query.toLowerCase()))
        // [
        //     { name: "seakashdiu", handle: "@seakashdiu1", img: "seakashdiu_img_url" }, // Replace with actual image URL
        //     { name: "Akash", handle: "@akashmia", img: "akash_img_url" }, // Replace with actual image URL
        //     { name: "All members on the card", handle: "@card", img: null },
        //     { name: "All members on the board", handle: "@board", img: null }
        // ].filter(user => user.name.toLowerCase().includes(query.toLowerCase()));

        setMentionSuggestions(suggestions);
        setSelectedSuggestionIndex(0); // Reset selected index when suggestions are fetched
    };

    const handleMentionClick = (mention) => {

        if (!quillRef.current) {
            return;
        }
        try {
            const quill = quillRef.current.getEditor();
            if (cursorPosition) {
                const textBeforeCursor = quill.getText(0, cursorPosition.index);
                const mentionIndex = textBeforeCursor.lastIndexOf('@');
                quill.deleteText(mentionIndex, cursorPosition.index - mentionIndex);

                const link = `@profile`; // Construct the link based on the mention
                const mentionText = `@${mention.username} `;

                // Insert the mention as a link
                quill.insertEmbed(mentionIndex, 'link', link);
                quill.insertText(mentionIndex, mentionText, 'link', link);

                // Move the cursor to the end of the inserted mention
                quill.setSelection(mentionIndex + mentionText.length);
                setShowMention(false);
            }
        } catch (error) {
            console.error("An error occurred while handling the mention click: ", error);
        }
    };

    return (
        <div className="quill-container" style={{ position: "relative" }}>
            <EditorToolbar cardId={cardId} />
            <ReactQuill
                ref={quillRef}
                theme="snow"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                modules={modules}
                formats={formats}
            />
            {showMention && mentionSuggestions.length > 0 && (
                <div
                    className="mention-popover"
                    style={{
                        position: "absolute",
                        top: mentionPosition.top + 50,
                        left: mentionPosition.left,
                        width: '220px'
                    }}
                >
                    {mentionSuggestions.map((suggestion, index) => {
                        console.log(suggestion.username);


                        return (
                            <div
                                key={index}
                                className={`mention-suggestion ${index === selectedSuggestionIndex ? "selected" : ""}`}
                                onClick={() => handleMentionClick(suggestion)}
                                style={{ display: "flex", alignItems: "center", padding: "5px 10px", cursor: "pointer" }}
                            >
                                {suggestion.profilePicture ? (
                                    <img src={suggestion.profilePicture} alt={suggestion.name} style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight: "10px" }} />
                                ) : (
                                    <div style={{ width: "30px", height: "30px", borderRadius: "50%", backgroundColor: "#ccc", marginRight: "10px" }} />
                                )}
                                <div>
                                    <div className="suggession-name" style={{ fontWeight: index === selectedSuggestionIndex ? "bold" : "normal" }}>{suggestion.name}</div>
                                    <div className="suggession-handler" style={{ color: "#888" }}>{`@${suggestion.username}`}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    );
};

export default forwardRef(Editor);
