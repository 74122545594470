import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/Slices/modalSlice';

import CreateWorkspace from './CreateWorkspace';


import './scss/index.scss'; // Assuming you have a CSS file for styles
import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';

const ProfileDropdown = () => {

  const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);


    const dispatch = useDispatch();

    const handleCreateWorkspace = () => {
        dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: { } }));
    };

    return (
        <div className="zoobbe-profile">
            <div className="account-section">
                <img src={user.user.profilePicture || 'https://trello-members.s3.amazonaws.com/6332a834fcd2a90107bee76e/70a3e515372b3d7874102eb1962165d9/50.png'} alt="Profile Picture" className="profile-picture" />
                <div className="account-info">
                    <p className="username">{user.user.username}</p>
                    <p className="email">{user.user.email}</p>
                </div>
            </div>
            <div className="manage-account-section">
                <a href="#" className="manage-account">Manage account</a>

            </div>
            <div className="menu-section">
                <p className="menu-title">Zoobbe</p>
                <ul>
                    <li><Link to={`/u/${user.user.username}`}>Profile and visibility</Link></li>
                    <li><Link to="#">Activity</Link></li>
                    <li><Link to="#">Cards</Link></li>
                    <li><Link to="#">Settings</Link></li>
                    <li><Link to="#">Theme</Link></li>
                </ul>
            </div>
            <div className="workspace-section">
                <button className="create-workspace" onClick={handleCreateWorkspace}>Create Workspace</button>
            </div>
            <div className="other-section">
                <ul>
                    <li><Link to="#">Help</Link></li>
                    <li><Link to="#">Shortcuts</Link></li>
                </ul>
            </div>
            <div className="logout-section">
                <ul>
                    <li><Link to="#">Log out</Link></li>

                </ul>
            </div>
        </div>
    );
};

export default ProfileDropdown;
