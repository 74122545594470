import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { showToast } from '../../redux/Slices/toastSlice';
import { setBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchUsers } from '../../redux/Slices/thunks';
import { fetchBoardMembers } from '../../redux/Slices/boardMembersSlice';
import { config } from '../../config';

import { useBoard } from '../../context/BoardContext';
import ActionList from './ActionList';
import CardDetails from './CardDetails';
import AddActionListButton from './AddActionListButton';
import Navbar from './Navbar';
import NotFound from '../400';

const Board = ({ isCardPopup }) => {
  const { boardId, boardSlug } = useParams();
  const { setBoardPermalink } = useBoard();

  const [showForm, setShowForm] = useState(false);
  const [listTitle, setActionListTitle] = useState('');
  const [board, setBoard] = useState({ actionLists: [] });
  const [isValid, setIsValid] = useState(true);
  const [openListId, setOpenListId] = useState(null);
  const textareaRef = useRef(null);

  const dispatch = useDispatch();

  const [_boardId, _setBoardId] = useState(boardId);
  const [_boardSlug, _setBoardSlug] = useState(boardSlug);

  const { users, loading: usersLoading, error: usersError } = useSelector((state) => state.users);

  useEffect(() => {
    if (!users) {
      dispatch(fetchUsers());
    }
  }, [dispatch, users]);

  useEffect(() => {
    const fetchBoard = async () => {
      if (!_boardId) return;

      try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(config.API_URI + `/api/boards/${_boardId}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          setIsValid(false);
          throw new Error('Failed to fetch boards');
        }

        const data = await response.json();
        const fetchedBoard = data && Array.isArray(data.actionLists) ? data : { actionLists: [] };
        setBoard(fetchedBoard);
        dispatch(setBoardId(_boardId));
      } catch (error) {
        console.error('Error fetching boards:', error);
        setBoard({ actionLists: [] });
      }
    };

    fetchBoard();
  }, [_boardId, dispatch]);

  useEffect(() => {
    if (_boardId && _boardSlug) {
      setBoardPermalink(`/b/${_boardId}/${_boardSlug}`);
    }
  }, [_boardId, _boardSlug, setBoardPermalink]);

  const handleAddList = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(config.API_URI + `/api/actionLists`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          boardId: _boardId,
          title: listTitle,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add action list');
      }

      const data = await response.json();
      const newList = data.actionList;
      const updatedBoard = {
        ...board,
        actionLists: [...board.actionLists, newList],
      };

      setBoard(updatedBoard);
      setActionListTitle('');
      setShowForm(false);
      dispatch(showToast({ message: 'Action list added successfully!', type: 'success' }));
    } catch (error) {
      console.error('Error adding action list:', error);
      dispatch(showToast({ message: 'Error adding action list', type: 'error' }));
    }
  };


  const updateBoard = async (newActionLists) => {
    try {
      const token = localStorage.getItem('accessToken');
      const boardId = _boardId; // The ID of the board you want to update
      const updateBody = {
        actionLists: newActionLists
      };

      const response = await fetch(`${config.API_URI}/api/boards/${boardId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updateBody),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const updatedBoard = await response.json();
      console.log('Updated Board:', updatedBoard);
    } catch (error) {
      console.error('Error updating board:', error);
    }

  };

  const handleShowForm = () => {
    setShowForm(true);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleHideForm = () => {
    setShowForm(false);
  };

  const handleToggleOptions = (listId) => {
    setOpenListId((prevListId) => (prevListId === listId ? null : listId));
  };

  const handleDeleteList = (listId) => {
    setBoard((prevBoard) => ({
      ...prevBoard,
      actionLists: prevBoard.actionLists.filter((list) => list._id !== listId),
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId, type } = result;

    if (type === 'ACTION_LIST') {
      const newActionLists = Array.from(board.actionLists);
      const [removed] = newActionLists.splice(source.index, 1);
      newActionLists.splice(destination.index, 0, removed);

      setBoard((prevBoard) => ({
        ...prevBoard,
        actionLists: newActionLists,
      }));

      console.log(newActionLists);
      updateBoard(newActionLists);

    } else if (type === 'CARD') {
      const sourceList = board.actionLists.find((list) => list._id === source.droppableId);
      const destinationList = board.actionLists.find((list) => list._id === destination.droppableId);

      const sourceCards = Array.from(sourceList.cards);

      if (source.droppableId === destination.droppableId) {
        // Move card within the same list
        const [movedCard] = sourceCards.splice(source.index, 1);
        sourceCards.splice(destination.index, 0, movedCard);

        const newActionLists = board.actionLists.map((list) => {
          if (list._id === source.droppableId) {
            return { ...list, cards: sourceCards };
          }
          return list;
        });

        setBoard((prevBoard) => ({
          ...prevBoard,
          actionLists: newActionLists,
        }));

        console.log(newActionLists);
        updateBoard(newActionLists);


      } else {
        // Move card to a different list
        const [removed] = sourceCards.splice(source.index, 1);
        const destinationCards = Array.from(destinationList.cards);
        destinationCards.splice(destination.index, 0, removed);

        const newActionLists = board.actionLists.map((list) => {
          if (list._id === source.droppableId) {
            return { ...list, cards: sourceCards };
          }
          if (list._id === destination.droppableId) {
            return { ...list, cards: destinationCards };
          }
          return list;
        });

        setBoard((prevBoard) => ({
          ...prevBoard,
          actionLists: newActionLists,
        }));

        console.log(newActionLists);
        updateBoard(newActionLists);


      }

    }


  };

  if (!isCardPopup && !isValid) {
    return <NotFound />;
  }

  return (
    <>
      <Navbar />
      <div className='zoobbe-workspace-board-container'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='all-lists' direction='horizontal' type='ACTION_LIST'>
            {(provided) => (
              <div
                className='zoobbe-board'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {board.actionLists.map((actionList, index) => (
                  <Draggable draggableId={actionList._id} index={index} key={actionList._id}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className='zoobbe-action-list'
                      >
                        <ActionList
                          actionList={actionList}
                          isOpen={openListId === actionList._id}
                          onToggleOptions={() => handleToggleOptions(actionList._id)}
                          onDelete={handleDeleteList}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {showForm && (
                  <form onSubmit={handleAddList}>
                    <textarea
                      ref={textareaRef}
                      name='add-new-card'
                      placeholder='Enter list title'
                      rows='1'
                      value={listTitle}
                      onChange={(e) => setActionListTitle(e.target.value)}
                      required
                    />
                    <div className='footer-buttons'>
                      <button type='submit'>Add List</button>
                      <button type='button' onClick={handleHideForm}>
                        <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                          <path
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414z'
                            fill='#000'
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                )}
                {!showForm && <AddActionListButton onClick={handleShowForm} />}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isCardPopup && <CardDetails _boardId={_boardId} _setBoardId={_setBoardId} _setBoardSlug={_setBoardSlug} />}

      </div>
    </>
  );
};

export default Board;
