import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toSlug } from '../../utils/helpers';

import { openModal } from '../../redux/Slices/modalSlice';

import CreateWorkspace from './CreateWorkspace';

import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';
import { config } from '../../config';


import './scss/workspace.scss';

import WorkspaceSidebar from './WorkspaceSidebar';
import BoardSection from './BoardSection';

const WorkSpace = () => {

    const [boards, setBoards] = useState([]);
    const [showFormForWorkspace, setShowFormForWorkspace] = useState(null);
    const [boardTitle, setBoardTitle] = useState('');
    const { workspaces, status, error } = useSelector(state => state.workspaces);


    const dispatch = useDispatch();

    const handleCreateWorkspace = () => {
        dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
    };

    const handleCreateBoard = (defaultSelectedOption) => {
        dispatch(openModal({ modalType: 'CREATE_BOARD', modalData: { defaultSelectedOption } }));
    };


    // useEffect(() => {
    //     if (!workspaces) {
    //         dispatch(fetchWorkspaces());
    //     }
    // }, [dispatch, workspaces])

    useEffect(() => {
        dispatch(fetchWorkspaces());
    }, [dispatch]);



    const [openWorkspaces, setOpenWorkspaces] = useState({});

    useEffect(() => {
        const initialOpenWorkspaces = {};
        workspaces.slice(0, 2).forEach(workspace => {
            initialOpenWorkspaces[workspace.name] = true;
        });
        setOpenWorkspaces(initialOpenWorkspaces);
    }, [workspaces]);

    const toggleOptions = (workspaceName) => {
        setOpenWorkspaces(prevState => ({
            ...prevState,
            [workspaceName]: !prevState[workspaceName]
        }));
    };

    useEffect(() => {
        const fetchActionLists = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(config.API_URI + `/api/boards`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    // setIsValid(false);
                    throw new Error('Failed to fetch boards');
                }

                const data = await response.json();
                setBoards(data);
            } catch (error) {
                console.error('Error fetching boards:', error);
            }
        };

        fetchActionLists();
    }, []);

    const handleAddButton = (workspaceId) => {
        setShowFormForWorkspace(prevWorkspaceId => prevWorkspaceId === workspaceId ? null : workspaceId);
    };

    const handleHideForm = () => {
        setShowFormForWorkspace(null);
    };


    // if (status === 'loading') {
    //     return <div>Loading...</div>;
    // }

    // if (status === 'failed') {
    //     return <div>Error: {error}</div>;
    // }


    return (
        <div className="zppbbe-workspace-wrapper">
            <div className="zoobbe-workspace-container">
                <aside className="zoobbe-sidebar">
                    <WorkspaceSidebar
                        openWorkspaces={openWorkspaces}
                        toggleOptions={toggleOptions}
                        handleCreateWorkspace={handleCreateWorkspace}
                    />
                </aside>

                <main className="zoobbe-main-content">
                    <div className="zoobbe-recently-viewed">
                        <h2>Recently viewed</h2>
                        <Link to={'#'} className="zoobbe-board-card">Task Board</Link>

                    </div>
                    {
                        workspaces.length > 0 && (
                            <section className="zoobbe-your-workspaces">
                                <h2>Your Workspaces</h2>
                                {workspaces.map((workspace, defaultSelectedOption) => {
                                    return (
                                        <div className="zoobbe-workspace-section" key={workspace._id}>
                                            <div className='boards-page-board-section-header'>
                                                <div className="zoobbe-workspace-card">
                                                    <span className="zoobbe-workspace-icon">{workspace.name ? workspace.name.charAt(0) : ''}</span>
                                                    <span className="zoobbe-workspace-title">{workspace.name}</span>
                                                </div>
                                                <div className="zoobbe-workspace-actions">
                                                    <Link to={`/w/${workspace.shortName}/Boards`} >Boards</Link>
                                                    <Link to={`/w/${workspace.shortName}/Views`} >Views</Link>
                                                    <Link to={`/w/${workspace.shortName}/Members`} >Members ({workspace.members.length})</Link>
                                                    <Link to={`/w/${workspace.shortName}/Settings`} >Settings</Link>
                                                    <Link to={`/w/${workspace.shortName}/Upgrade`} >Upgrade</Link>
                                                </div>
                                            </div>

                                            <BoardSection boards={workspace.boards} defaultSelectedOption={defaultSelectedOption} />

                                        </div>
                                    )
                                })}
                            </section>
                        )
                    }

                    <section className="zoobbe-guest-workspaces">
                        <h2>Guest Workspaces</h2>
                        <div className="zoobbe-workspace-card">Akash</div>
                    </section>
                </main>
            </div>
        </div>
    );
};

export default WorkSpace;
