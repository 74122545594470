import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

export const fetchLabels = createAsyncThunk(
    'labels/fetchLabels',
    async ({ cardId }, thunkAPI) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/labels`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch labels');
            }

            const data = await response.json();
            return { cardId, labels: data.labels };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const labelSlice = createSlice({
    name: 'labels',
    initialState: {
        labelsByCard: {},
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLabels.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLabels.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const { cardId, labels } = action.payload;
                state.labelsByCard[cardId] = labels;
            })
            .addCase(fetchLabels.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default labelSlice.reducer;
