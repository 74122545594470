import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addMember, fetchCardMembers } from '../../utils/helpers';
import { fetchCardMembers as fetchCardMembersAction } from '../../redux/Slices/cardMemberSlice';
import { togglePopover, updatePopoverPosition } from '../../redux/Slices/popoverSlice';
import { uploadAttachment } from '../../redux/Slices/attachmentSlice';

const CardDetailsSidebar = ({ cardId }) => {
    const { user } = useSelector((state) => state.user);
    const { membersByCard } = useSelector((state) => state.cardMembers);
    const members = membersByCard[cardId] || [];
    const [error, setError] = useState(null);
    const fileInputRef = useRef(null);

    const dispatch = useDispatch();

    const handleJoinToCard = async (e, member) => {
        e.preventDefault();
        setError(null);
        try {
            await addMember({ cardId, memberId: member.user._id });
            dispatch(fetchCardMembersAction(cardId));
        } catch (error) {
            setError('Failed to add member.');
        }
    };

    const isUserMember = members.some(member => member?.user?._id === user?._id);

    const [popoverState, setPopoverState] = useState({ isVisible: false, contentId: null });

    const handlePopoverClick = (e, contentId) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };

        if (popoverState.isVisible && popoverState.contentId === contentId) {
            setPopoverState({ isVisible: false, contentId: null });
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
        } else {
            setPopoverState({ isVisible: true, contentId });
            dispatch(togglePopover({ contentId, position, target }));
        }
    };

    

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            dispatch(uploadAttachment({ file, cardId }));
        }
    };

    const actionButtons = [
        !isUserMember && {
            title: 'Join',
            icon: 'person_outline',
            text: 'Join',
            onClick: (e) => handleJoinToCard(e, user),
            id: 'card-back-join-button'
        },
        {
            title: 'Members',
            icon: 'people_outline',
            text: 'Members',
            onClick: (e) => handlePopoverClick(e, 'addChecklist'),
            id: 'card-back-members-button'
        },
        {
            title: 'Labels',
            icon: 'label_outline',
            text: 'Labels',
            onClick: (e) => handlePopoverClick(e, 'addLabels'),
            id: 'card-back-labels-button'
        },
        {
            title: 'Checklist',
            icon: 'checklist',
            text: 'Checklist',
            onClick: (e) => handlePopoverClick(e, 'addChecklist'),
            id: 'card-back-checklist-button'
        },
        {
            title: 'Dates',
            icon: 'hourglass_top',
            text: 'Dates',
            onClick: () => console.log('Dates button clicked'),
            id: 'card-back-dates-button'
        },
        {
            title: 'Attachment',
            icon: 'attach_file',
            text: 'Attachment',
            onClick: () => fileInputRef.current.click(),
            id: 'card-back-attachment-button'
        },
        {
            title: 'Cover',
            icon: 'video_label',
            text: 'Cover',
            onClick: () => console.log('Cover button clicked'),
            id: 'card-back-cover-button'
        },
        {
            title: 'Move',
            icon: 'arrow_right_alt',
            text: 'Move',
            onClick: () => console.log('Move button clicked'),
            id: 'card-back-move-card-button'
        },
        {
            title: 'Copy',
            icon: 'copy_all',
            text: 'Copy',
            onClick: () => console.log('Copy button clicked'),
            id: 'card-back-copy-card-button'
        },
        {
            title: 'Make template',
            icon: 'web_asset',
            text: 'Make template',
            onClick: () => console.log('Make template button clicked'),
            id: 'card-back-make-template-button'
        },
        {
            title: 'Archive',
            icon: 'archive',
            text: 'Archive',
            onClick: () => console.log('Archive button clicked'),
            id: 'card-back-archive-button'
        },
        {
            title: 'Send to board',
            icon: 'refresh',
            text: 'Send to board',
            onClick: () => console.log('Send to board button clicked'),
            id: 'card-back-send-to-board-button'
        },
        {
            title: 'Delete',
            icon: 'delete_outline',
            text: 'Delete',
            onClick: () => console.log('Delete button clicked'),
            id: 'card-back-delete-card-button'
        },
        {
            title: 'Share',
            icon: 'share',
            text: 'Share',
            onClick: () => console.log('Share button clicked'),
            id: 'card-back-share-button'
        }
    ].filter(Boolean);

    return (
        <div className="window-sidebar">
            <div className="window-module u-clearfix">
                <h3 className="mod-no-top-margin js-sidebar-add-heading">Add to card</h3>
                <ul className="action-buttons-list">
                    {actionButtons.map((button, index) => (
                        <li key={index} className="action-button-item">
                            <button
                                className="button-with-icon"
                                type="button"
                                onClick={button.onClick}
                                title={button.title}
                                id={button.id}
                                data-testid={button.id}
                            >
                                <span className="material-icons">{button.icon}</span>
                                {button.text}
                            </button>

                            {
                                button.text === 'Attachment' && (
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileUpload}
                                    />
                                )
                            }
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default CardDetailsSidebar;
