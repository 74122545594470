// src/components/Checklist.js

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChecklists, addChecklistItem, updateChecklistItem, deleteChecklistItem, deleteChecklist } from '../../redux/Slices/checklistSlice';

import IconChecklist from '../icons/IconChecklist';

import './scss/Checklists.scss';

const Checklists = ({ cardId }) => {
    const dispatch = useDispatch();
    const [newItem, setNewItem] = useState('');
    const [editingItemId, setEditingItemId] = useState(null);
    const [editItemTitle, setEditItemTitle] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [visibleAddNew, setVisibleAddNew] = useState(null); // Track which checklist's new item input is visible
    const [hideCheckedItems, setHideCheckedItems] = useState(false); // Track whether to hide checked items
    const checklistItemRef = useRef(null);

    const { checklists, status, error } = useSelector((state) => state.checklists);
    

    useEffect(() => {
        dispatch(fetchChecklists(cardId));
    }, [dispatch, cardId]);

    const handleCheck = (checklistId, itemId, itemTitle) => {
        if (!itemTitle || itemTitle.length === 0) {
            console.log('Item title is empty:', itemTitle);
            return;
        }
        const item = checklists.find(checklist => checklist._id === checklistId).items.find(item => item._id === itemId);
        dispatch(updateChecklistItem({ cardId, checklistId, itemId, title: itemTitle, checked: !item.checked }));
    };

    const handleAddItem = (checklistId) => {
        if (newItem) {
            dispatch(addChecklistItem({ cardId, checklistId, newItem }));
            setNewItem('');
            setVisibleAddNew(null);
        }
    };

    const updateItemTitle = (checklistId, itemId, title, checked) => {
        if (title) {
            dispatch(updateChecklistItem({ cardId, checklistId, itemId, title, checked }));
            setIsEditing(false);
            setEditingItemId(null);
            setEditItemTitle('');
        }
    };

    const handleDeleteItem = (checklistId, itemId) => {
        dispatch(deleteChecklistItem({ cardId, checklistId, itemId }));
    };

    const handleDeleteChecklist = (checklistId) => {
        dispatch(deleteChecklist({ cardId, checklistId }));
    };

    useEffect(() => {
        if (checklistItemRef.current) {
            checklistItemRef.current.style.height = '24px'; // Reset to auto height initially
            checklistItemRef.current.style.height = `${checklistItemRef.current.scrollHeight}px`; // Adjust height based on content
            const lineCount = Math.ceil(checklistItemRef.current.scrollHeight / 24);
            checklistItemRef.current.style.height = `${(lineCount * 24) - 24}px`;

            // checklistItemRef.current.focus();
            // checklistItemRef.current.select();

        }
    }, [editItemTitle, newItem]);

    useEffect(() => {
        if (checklistItemRef.current) {
            checklistItemRef.current.focus();
            checklistItemRef.current.select();
        }
    }, [visibleAddNew, editingItemId]);

    const progress = (items) => items?.length ? (items?.filter(item => item?.checked).length / items.length) * 100 : 0;

    return (
        checklists && (
            checklists.map(checklist => (
                <div className="zoobbe-checklist" key={checklist._id}>
                    <div className="checklist-header">
                        <h3>
                            <IconChecklist />
                            {checklist.title}
                        </h3>

                        <div className="checklist-more-buttons">
                            <button
                                className="hide-checked-item-btn"
                                onClick={() => setHideCheckedItems(!hideCheckedItems)}
                            >
                                {hideCheckedItems ? 'Show checked items' : 'Hide checked items'}
                            </button>
                            <button
                                className="checklist-delete-btn"
                                onClick={() => handleDeleteChecklist(checklist._id)}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <div className="progress-bar">
                        <span>{Math.round(progress(checklist.items))}%</span>
                        <div className='checklist-progress-bar'>
                            <div className="progress" style={{ width: `${progress(checklist.items)}%` }}></div>
                        </div>
                    </div>
                    <div className="checklist-items">
                        {checklist.items.filter(item => !hideCheckedItems || !item.checked).map(item => (
                            item && item._id && (
                                <div className={`checklist-item ${(isEditing && editingItemId === item._id) ? 'currently-editing' : ''} ${item.checked ? 'checked' : ''}`} key={item._id}>
                                    <input type="checkbox" className='custom-checkbox' id={`item-${item._id}`} checked={item.checked} onChange={() => handleCheck(checklist._id, item._id, item.title)} />
                                    {editingItemId !== item._id ? (
                                        <>
                                            <label onClick={() => {
                                                setEditingItemId(item._id);
                                                setEditItemTitle(item.title);
                                                setIsEditing(true);
                                            }}>{item.title}</label>
                                            <button className="delete-item-btn" onClick={() => handleDeleteItem(checklist._id, item._id)}>Delete</button>
                                        </>
                                    ) : (
                                        <div className="checklist-footer-container">
                                            <div className="add-item">
                                                <textarea
                                                    type="text"
                                                    ref={checklistItemRef}
                                                    placeholder="Add an item"
                                                    value={editItemTitle}
                                                    onChange={(e) => setEditItemTitle(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            updateItemTitle(checklist._id, item._id, editItemTitle, item.checked);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="checklist-footer">
                                                <div className='left-button'>
                                                    <button className="add-btn" onClick={() => updateItemTitle(checklist._id, item._id, editItemTitle, item.checked)}>Save</button>
                                                    <button className="cancel-btn" onClick={() => {
                                                        setEditingItemId(null);
                                                        setEditItemTitle('');
                                                        setIsEditing(false);
                                                    }}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414z" fill="currentColor"></path></svg>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )
                        ))}
                        {visibleAddNew === checklist._id ? (
                            <div className="checklist-footer-container">
                                <div className="add-item">
                                    <textarea
                                        type="text"
                                        ref={checklistItemRef}
                                        placeholder="Add an item"
                                        value={newItem}
                                        onChange={(e) => setNewItem(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleAddItem(checklist._id);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="checklist-footer">
                                    <div className='left-button'>
                                        <button className="add-btn" onClick={() => handleAddItem(checklist._id)}>Add</button>
                                        <button className="cancel-btn" onClick={() => { setNewItem(''); setVisibleAddNew(null); }}>Discard</button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <button className="add-new-item-btn" onClick={() => setVisibleAddNew(checklist._id)}>Add item</button>
                        )}
                    </div>
                </div>
            ))
        )
    );
};

export default Checklists;
