import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../redux/Slices/thunks';

import ProfileDropdown from '../Profile/ProfileDropdown';

import './index.scss'; // Assuming you have a CSS file for styles

const Header = () => {
  const [isShowProfile, setIsShowProfile] = useState(false);

  const profileRef = useRef(null); // Ref for the profile element

  const dispatch = useDispatch();
  const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user])


  const handleShowProfile = () => {
    setIsShowProfile(!isShowProfile);
  };

  const handleClickOutside = (event) => {
    if (profileRef.current && !profileRef.current.contains(event.target)) {
      setIsShowProfile(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  if (userLoading) return <div>Loading...</div>;
  if (userError) return <div>Error: {userError}</div>;

  return (
    <header className="zoobbe-header">
      <div className="zoobbe-header__logo"><Link to={'/'} >Zoobbe</Link></div>
      <div className="zoobbe-header__menu">
        <Link to="/workspaces" className="zoobbe-header__menu-item">Workspaces</Link>
        <Link to="/recent" className="zoobbe-header__menu-item">Recent</Link>
        <Link to="/starred" className="zoobbe-header__menu-item">Starred</Link>
        <Link to="/templates" className="zoobbe-header__menu-item">Templates</Link>
        <Link to="/#" className="zoobbe-header__menu-item">Logout</Link>
        <Link to="/create" className="zoobbe-header__menu-item zoobbe-header__menu-item--create">Create</Link>
      </div>
      <div className="zoobbe-header__search-container">
        <svg
          className="zoobbe-header__search-icon"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          width={20}
          height={20}
          color="white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-4.35-4.35M11 17a6 6 0 100-12 6 6 0 000 12z"
          />
        </svg>
        <input
          className="zoobbe-header__search-input"
          type="text"
          placeholder="Search"
        />
      </div>
      <div className="zoobbe-header__icons">
        <svg
          className="zoobbe-header__icons-notification"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          width={20}
          height={20}
          color="white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a7.002 7.002 0 00-4-6.326V4a2 2 0 10-4 0v.674a7.002 7.002 0 00-4 6.326v3.159c0 .415-.152.822-.405 1.137L4 17h5m1 0v2a3 3 0 006 0v-2m-6 0a3 3 0 01-6 0v-2m6 2a3 3 0 006 0v-2m-6 2a3 3 0 01-6 0v-2"
          />
        </svg>
        <div className="profile-container" ref={profileRef}>
          <div className="profile-image" onClick={handleShowProfile}>
            <img
              className="zoobbe-header__icons-avatar"
              src={user?.user?.profilePicture || 'https://trello-members.s3.amazonaws.com/6332a834fcd2a90107bee76e/70a3e515372b3d7874102eb1962165d9/50.png'}
              alt={user?.user?.username}
            />
          </div>

          {
            isShowProfile && (
              <ProfileDropdown userInfo={user}/>
            )
          }
        </div>
      </div>
    </header>
  );
};

export default Header;
