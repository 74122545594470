// redux/Slices/popoverSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isVisible: false,
    contentId: null,
    position: { top: 0, left: 0 },
    target: null,
};

const popoverSlice = createSlice({
    name: 'popover',
    initialState,
    reducers: {
        showPopover: (state, action) => {
            state.isVisible = true;
            state.contentId = action.payload.contentId;
            state.position = action.payload.position;
            state.target = action.payload.target;
        },
        hidePopover: (state) => {
            state.isVisible = false;
            state.contentId = null;
            state.position = { top: 0, left: 0 };
            state.target = null;
        },
        togglePopover: (state, action) => {
            if (state.isVisible && state.contentId === action.payload.contentId) {
                // Hide the popover if it's already visible for the same contentId
                state.isVisible = false;
                state.contentId = null;
                state.position = { top: 0, left: 0 };
                state.target = null;
            } else {
                // Show the popover for the new contentId
                state.isVisible = true;
                state.contentId = action.payload.contentId;
                state.position = action.payload.position;
                state.target = action.payload.target;
            }
        },
        updatePopoverPosition: (state, action) => {
            state.position = action.payload;
        },
    },
});

export const { showPopover, hidePopover, togglePopover, updatePopoverPosition } = popoverSlice.actions;
export default popoverSlice.reducer;
