import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../redux/Slices/toastSlice';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchBoardMembers } from '../../redux/Slices/boardMembersSlice';
import ZoobbeSelect from '../Global/ZoobbeSelect';

import { togglePopover } from '../../redux/Slices/popoverSlice';
import { memberInfo } from '../../redux/Slices/memberSlice';
import { config } from '../../config';


import './scss/ShareBoard.scss';
import ImagePlaceholder from '../Global/ImagePlaceholder';


const ShareBoardModal = () => {
    const [email, setEmail] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [message, setMessage] = useState("Join me on Trello and let's work together on this board!");
    const [activeTab, setActiveTab] = useState('members');
    const [searchResults, setSearchResults] = useState([]);

    const dispatch = useDispatch();
    const { users, loading: usersLoading, error: usersError } = useSelector((state) => state.users);
    const { members, loading: membersLoading, error: membersError } = useSelector((state) => state.boardMembers);
    const boardId = useSelector(selectBoardId);

    const [currentMembers, setCurrentMembers] = useState(members);

    const [size, setSize] = useState(35);

    // useEffect(() => {
    //     dispatch(fetchBoardMembers(boardId)); // Refresh members
    // }, [dispatch, boardId]);

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        if (value?.length > 0) {
            const results = users?.filter(item =>
                item.name.toLowerCase().includes(value.toLowerCase()) ||
                item.email.toLowerCase().includes(value.toLowerCase())
            );
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    };

    const removeUser = (index) => {
        const updatedUsers = [...selectedUsers];
        updatedUsers.splice(index, 1);
        setSelectedUsers(updatedUsers);
    };

    const handleSelectUser = (user) => {
        if (!selectedUsers.some(selected => selected._id === user._id)) {
            setSelectedUsers([...selectedUsers, user]);
            setEmail('');
            setSearchResults([]);
        }
    };

    const addMemberToBoard = async (e) => {
        e.preventDefault();

        try {
            const token = localStorage.getItem('accessToken');

            const response = await fetch(config.API_URI + `/api/boards/${boardId}/add-member`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    boardId: boardId,
                    userIds: selectedUsers.map(user => user._id),
                }),
            });

            const data = await response.json();

            if (response.status === 200) {
                dispatch(showToast({ message: data.message, type: 'success' }));
                setCurrentMembers(data.members);

            } else {
                dispatch(showToast({ message: data.message || 'Failed to add member', type: 'error' }));
            }
        } catch (error) {
            console.error('Error adding member:', error);
            dispatch(showToast({ message: 'Error adding member', type: 'error' }));
        }
    };

    const options = [
        { value: 'member', label: 'Member' },
        { value: 'observer', label: 'Observer' }
    ];

    const [selectedRole, setSelectedRole] = useState(options[0]);

    const handleRoleSelect = (role) => {
        setSelectedRole(role);
        console.log('Selected role:', role);
    };

    const [popoverState, setPopoverState] = useState({ isVisible: false, contentId: null });

    const handleMemberInfo = (e, contentId, member) => {
        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };

        if (popoverState.isVisible && popoverState.contentId === contentId) {
            setPopoverState({ isVisible: false, contentId: null });
            dispatch(togglePopover({ contentId: null, position: { top: 0, left: 0 }, target: null }));
        } else {
            setPopoverState({ isVisible: true, contentId });
            dispatch(togglePopover({ contentId, position, target }));
            dispatch(memberInfo({ member }));
        }
    };

    if (usersLoading || membersLoading) return <div>Loading...</div>;
    if (usersError) return <div>Error: {usersError}</div>;
    if (membersError) return <div>Error: {membersError}</div>;

    return (
        <div className="sbm-share-board-modal">
            <div className="sbm-header">
                <h3>Share board</h3>
            </div>
            <form onSubmit={addMemberToBoard}>
                <div className="sbm-input-group">
                    <div className="sbm-email-input">
                        {selectedUsers.map((selectedUser, index) => (
                            <div key={index} className="sbm-selected-email">
                                {selectedUser.name}
                                <button type="button" onClick={() => removeUser(index)}>×</button>
                            </div>
                        ))}
                        <input
                            type="text"
                            value={email}
                            onChange={handleEmailChange}
                            placeholder={'Type name or email'}
                            autoComplete="off"
                        />
                        {searchResults?.length > 0 && (
                            <div className="sbm-search-results">
                                {searchResults.map((result, index) => (
                                    <div key={index} onClick={() => handleSelectUser(result)} className="sbm-search-result">
                                        <span>{result.name}</span>
                                        <span>{result.email}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <ZoobbeSelect
                        options={options}
                        defaultSelectedOption={0}
                        onSelect={handleRoleSelect}
                    />
                </div>
                <button type="submit" className="sbm-share-button">Share</button>
            </form>
            <textarea
                className="sbm-message-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            <div className="sbm-share-link">
                <button className="sbm-create-link-button">Create link</button>
            </div>
            <div className="sbm-tabs">
                <button
                    className={`sbm-tab ${activeTab === 'members' ? 'active' : ''}`}
                    onClick={() => setActiveTab('members')}
                >
                    Board members
                </button>
                <button
                    className={`sbm-tab ${activeTab === 'requests' ? 'active' : ''}`}
                    onClick={() => setActiveTab('requests')}
                >
                    Requests
                </button>
            </div>
            {activeTab === 'members' && (
                <div className="sbm-board-members">
                    {currentMembers?.map((member) => (
                        <div className="sbm-member" key={member._id}>
                            {
                                member.profilePicture ? (
                                    <span className='avatar' key={member._id} onClick={(e) => handleMemberInfo(e, 'memberInfo', member)}>
                                        <img className={'sbm-member-image'} style={{ width: size, height: size }} src={member.profilePicture} alt={member.name} />
                                    </span>
                                ) : (
                                    <span className='avatar sbm-member-image' key={member._id} onClick={(e) => handleMemberInfo(e, 'memberInfo', member)} >
                                        <ImagePlaceholder size={size} text={member.username} />
                                    </span>
                                )
                            }
                            <span className="sbm-member-name">{member.username} (you)</span>
                            <span className="sbm-member-role">Workspace admin</span>
                            <ZoobbeSelect
                                options={options}
                                defaultSelectedOption={0}
                                onSelect={handleRoleSelect}
                            />
                        </div>
                    ))}
                </div>
            )}
            {activeTab === 'requests' && (
                <div className="sbm-requests">
                    <h4>Requests</h4>
                    {/* Add request items here */}
                </div>
            )}
        </div>
    );
};

export default ShareBoardModal;
