// memberSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    member: null,
};

const memberSlice = createSlice({
    name: 'member',
    initialState,
    reducers: {
        memberInfo: (state, action) => {
            state.member = action.payload.member;
        },
    },
});

export const { memberInfo } = memberSlice.actions;
export default memberSlice.reducer;
