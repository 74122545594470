import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './scss/AddCardLabels.scss';
import { fetchLabels } from '../../redux/Slices/labelSlice';
import { config } from '../../config';

const AddLabels = (props) => {
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [currentLabel, setCurrentLabel] = useState('');
    const [currentColor, setCurrentColor] = useState('');
    const [editLabel, setEditLabel] = useState(null);

    const [labels, setLabels] = useState([]);

    const { card } = useSelector((state) => state.card);
    const cardId = card._id;

    const dispatch = useDispatch();

    useEffect(() => {
        // Fetch initial labels for the card
        const fetchLabels = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(config.API_URI + `/api/cards/${cardId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch labels');
                }

                const card = await response.json();
                setLabels([...labels, ...card.labels]);
            } catch (error) {
                console.error('Error fetching labels:', error);
            }
        };

        fetchLabels();
    }, [cardId]);

    const openEditModal = (label = '', color = '#61bd4f') => {
        setCurrentLabel(label);
        setCurrentColor(color);
        setEditModalOpen(true);
    };

    const closeEditModal = () => {
        setEditModalOpen(false);
    };

    const createLabel = async () => {
        if (currentLabel.trim()) {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(config.API_URI + `/api/cards/${cardId}/labels`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        text: currentLabel,
                        color: currentColor,
                        enabled: true
                    })
                });

                if (!response.ok) {
                    throw new Error('Failed to add label');
                }

                const updatedCard = await response.json();
                const newLabel = { _id: updatedCard.labels.slice(-1)[0]._id, text: updatedCard.labels.slice(-1)[0].text, color: updatedCard.labels.slice(-1)[0].color, enabled: updatedCard.labels.slice(-1)[0].enabled };

                setLabels([...labels, newLabel]);
                setEditModalOpen(false);
            } catch (error) {
                console.error('Error adding label:', error);
            }
        }
    };

    const updateLabel = async (label, labelId) => {
        try {
            const token = localStorage.getItem('accessToken');
            const url = config.API_URI + `/api/cards/${cardId}/labels/${labelId}`;
            const method = 'PUT'; // Toggle label enabled state

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ...label, enabled: !label.enabled })
            });

            if (!response.ok) {
                throw new Error('Failed to toggle label');
            }

            const updatedCard = await response.json();


            // Extract the updated label
            const updatedLabel = updatedCard.labels.find(l => l._id === label._id);

            console.log(updatedLabel);

            // Update the labels state
            const updatedLabels = labels.map(l => l._id === updatedLabel._id ? updatedLabel : l);
            setLabels(updatedLabels);
            dispatch(fetchLabels({ cardId }));
            closeEditModal();
        } catch (error) {
            console.error('Error toggling label:', error);
        }
    };

    const deleteLabel = async (labelId) => {
        try {
            const token = localStorage.getItem('accessToken');
            const url = config.API_URI = `/api/cards/${cardId}/labels/${labelId}`;
            const method = 'DELETE';

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete label');
            }

            const updatedCard = await response.json();
            const updatedLabels = updatedCard.labels;
            setLabels(updatedLabels);
            closeEditModal();

        } catch (error) {
            console.error('Error deleting label:', error);
        }
    };

    return (
        <div className="labels-container">
            {isEditModalOpen ? (
                <div className="edit-label-modal">
                    <div className="modal-header">
                        <button className="back-button" onClick={closeEditModal}><span className="material-icons">keyboard_backspace</span></button>
                        <h3>Edit label</h3>
                    </div>
                    <div className="label-preview" style={{ backgroundColor: currentColor }}>
                        {currentLabel}
                    </div>
                    <label>Title</label>
                    <input
                        type="text"
                        value={currentLabel}
                        onChange={e => setCurrentLabel(e.target.value)}
                        className="title-input"
                    />
                    <label>Select a color</label>
                    <div className="color-picker">
                        {['#61bd4f', '#f2d600', '#ff9f1a', '#eb5a46', '#c377e0', '#0079bf', '#519839', '#d9b51c', '#e79217', '#b04632', '#89609e', '#055a8c', '#6bbd6b', '#efd700', '#f47b13', '#cd5a91', '#6c8ebf', '#5aac44', '#f5dd29', '#ffab4a', '#ef7564', '#cd8de5', '#5ba4cf', '#29cce5', '#6deca9', '#ff8ed4', '#172b4d', '#4d4d4d'].map(color => (
                            <div
                                key={color}
                                className={`color-box ${currentColor === color ? 'selected' : ''}`}
                                style={{ backgroundColor: color }}
                                onClick={() => setCurrentColor(color)}
                            ></div>
                        ))}
                    </div>
                    <button className="remove-color-button" onClick={() => setCurrentColor('')}>
                        &times; Remove color
                    </button>
                    <div className="modal-footer">
                        <button className="save-button" onClick={() => createLabel()}>Create</button>
                        {editLabel && (
                            <>
                                <button className="save-button" onClick={() => updateLabel({ text: currentLabel, color: currentColor, enabled: editLabel.enabled }, editLabel._id)}>Save</button>
                                <button onClick={() => deleteLabel(editLabel._id)} className="delete-button">Delete</button>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <>
                    <div className="labels-header">
                        <h3>Labels</h3>
                    </div>
                    <input type="text" placeholder="Search labels..." className="search-input" />
                    <div className="labels-list">
                        {labels.map(label => (
                            <div className="label-item" key={label._id}>
                                <input
                                    type="checkbox"
                                    checked={label.enabled}
                                    onChange={() => updateLabel(label, label._id)}
                                />
                                <div className="label-color" style={{ backgroundColor: label.color }}>{label.text}</div>
                                <button className="edit-button" onClick={() => { setEditLabel(label); openEditModal(label.text, label.color); }}>
                                    <span className="material-icons">colorize</span>
                                </button>
                            </div>
                        ))}
                    </div>
                    <button className="create-label-button" onClick={() => openEditModal()}>Create a new label</button>
                </>
            )}
        </div>
    );
};

export default AddLabels;
