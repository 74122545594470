import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ZoobbeSelect from '../Global/ZoobbeSelect';

import './scss/AddChecklist.scss';
import { fetchChecklists } from '../../redux/Slices/checklistSlice';
import { hidePopover } from '../../redux/Slices/popoverSlice';
import { config } from '../../config';

const AddChecklist = () => {
    const [title, setTitle] = useState('Checklist');
    const [selectedChecklist, setSelectedChecklist] = useState(null);

    const inputRef = useRef(null);
    const { card } = useSelector((state) => state.card);
    const cardId = card._id;


    const dispatch = useDispatch();

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, []);

    const checklistOptions = [
        { value: 'none', label: '(none)' },
    ];

    const handleChecklistSelect = (checklist) => {
        setSelectedChecklist(checklist);
        console.log('Selected Checklist:', checklist);
    };

    const handleAddChecklist = async () => {
        if (!title) {
            alert("Please enter a title for the checklist");
            return;
        }

        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/checklists`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // replace with your token
                },
                body: JSON.stringify({ title })
            });

            if (response.ok) {
                const updatedCard = await response.json();
                dispatch(fetchChecklists(cardId));
                dispatch(hidePopover());
                setTitle('Checklist');
            } else {
                console.error('Failed to add checklist');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="add-checklist">
            <h2>Add checklist</h2>

            <div className="group">
                <h3>Title</h3>
                <input
                    type="text"
                    placeholder="Checklist"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    ref={inputRef}
                />
            </div>
            <div className="group">
                <h3>Copy items from…</h3>
                <ZoobbeSelect
                    options={checklistOptions}
                    defaultSelectedOption={0}
                    onSelect={handleChecklistSelect}
                />
            </div>
            <button onClick={handleAddChecklist}>Add</button>
        </div>
    );
};

export default AddChecklist;
