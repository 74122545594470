import react, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCardMembers } from '../../redux/Slices/cardMemberSlice';

import MembersPopover from './MembersPopover';

import MemberImage from '../Global/MemberImage';


const CardMembers = ({ cardId }) => {
    const [isPopoverOpen, setPopoverOpen] = useState(false);

    const dispatch = useDispatch();

    const { membersByCard, loading, error } = useSelector((state) => state.cardMembers);

    const members = membersByCard[cardId] || [];

    useEffect(() => {
        dispatch(fetchCardMembers(cardId));
    }, [dispatch, cardId]);

    return (
        <>
            {
                members.length > 0 && (

                    <div className="zoobbe-card-members">
                        <h5>Members</h5>
                        <div className='member-list'>

                            <div className="avatars">
                                <MemberImage members={members} size={35} />
                            </div>

                            <div className='add-member-to-card' onClick={() => setPopoverOpen(true)}>
                                <svg width={24} height={24} viewBox="0 0 0.72 0.72" xmlns="http://www.w3.org/2000/svg" className="icon flat-color">
                                    <path d="M.36.6A.03.03 0 0 1 .33.57V.39H.15a.03.03 0 0 1 0-.06h.18V.15a.03.03 0 0 1 .06 0v.18h.18a.03.03 0 0 1 0 .06H.39v.18A.03.03 0 0 1 .36.6" style={{ fill: "#000" }} />
                                </svg>
                            </div>


                            {isPopoverOpen && (
                                <MembersPopover
                                    cardMembers={members}
                                    cardId={cardId}
                                    onClose={() => setPopoverOpen(false)}
                                />
                            )}
                        </div>
                    </div>
                )
            }
        </>
    )
}


export default CardMembers;