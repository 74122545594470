import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useBoard } from '../../context/BoardContext';

import { sanitizeHtml, toSlug, uploadFile } from '../../utils/helpers';
import { config } from '../../config';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import CardDetailsSidebar from '../Workspaces/CardDetailsSidebar';
import NotFound from '../400';

import {
    fetchAttachments,
    uploadAttachment,
    editAttachment,
    deleteAttachment,
    resetStatus,
} from '../../redux/Slices/attachmentSlice';

import './scss/editor.style.scss';
import CardMemberSlice, { fetchCardMembers, addCardMember, removeCardMember } from '../../redux/Slices/cardMemberSlice';
import Comments from './Comments';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import PopOver from '../Global/PopOver';
import Checklist from './Checklists';
import IconCardTitle from '../icons/IconCardTitle';
import IconDescription from '../icons/IconDescription';
import Attachments from './Attachments';
import CardMembers from './CardMembers';
import CardLabels from './CardLabels';
import Editor from '../Editor/Editor';
import { cardData } from '../../redux/Slices/cardSlice';


const CardDetails = ({ _setBoardId, _setBoardSlug }) => {
    const { cardId } = useParams();
    const navigate = useNavigate(); // Use navigate hook
    const [card, setCardDetails] = useState({});
    const { boardPermalink } = useBoard();
    const [isValid, setIsValid] = useState(true);
    const [title, setTitle] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [description, setDescription] = useState('');
    const [isEditingDescription, setIsEditingDescription] = useState(false);

    const textAreaRef = useRef(null);
    const quillRef = useRef(null);
    const fileInputRef = useRef(null);

    const [actionListId, setActionListId] = useState(null);
    const [actionListTitle, setActionListTitle] = useState(null);

    const dispatch = useDispatch();

    const attachments = useSelector((state) => state.attachments.attachments);
    const attachmentStatus = useSelector((state) => state.attachments.status);

    useEffect(() => {
        if (attachmentStatus === 'idle') {
            dispatch(fetchAttachments(cardId));
        }
    }, [attachmentStatus, dispatch, cardId]);

    useEffect(() => {
        if (attachmentStatus === 'uploadSucceeded') {
            dispatch(fetchAttachments(cardId)).then(() => {
                dispatch(resetStatus());
            });
        }
    }, [attachmentStatus, dispatch, cardId]);


    const handleFileUpload = async (e) => {
        e.preventDefault();

        if (!(quillRef.current)) {
            return;
        }
        try {
            const attachments = await uploadFile(e, cardId);

            const quill = quillRef?.current?.getEditor();

            const range = quill.getSelection();
            quill.insertEmbed(range.index, 'link', attachments.attachment.url);

            dispatch(fetchAttachments({ cardId }));

        } catch (error) {
            console.error('Error uploading file:', error.message);
        }
    };

    const modules = {
        toolbar: {
            container: [
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['link', 'image'],
                ['code-block'],
                [{ 'attachment': 'attachment' }]
            ],
            handlers: {
                'attachment': () => {
                    fileInputRef?.current?.click();
                }
            }
        }
    };


    useEffect(() => {
        const fetchCardDetails = async () => {

            const token = localStorage.getItem('accessToken');

            if (!cardId) {
                console.log("Card ID not found.");
                return;
            }
            try {
                const response = await fetch(config.API_URI + `/api/cards/${cardId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    setIsValid(false);
                    throw new Error('Failed to fetch card details');
                }
                const data = await response.json();

                setCardDetails(data);
                setTitle(data.title);
                setDescription(data.description || '');
                // setAttachments(data.attachments || []);
                setActionListId(data.actionList);

                dispatch(cardData(data));

                // console.log(data);

            } catch (error) {
                console.error('Error fetching card details:', error);
            }

        };

        fetchCardDetails();
    }, [cardId]);


    useEffect(() => {
        const fetchBoardData = async () => {

            const token = localStorage.getItem('accessToken');

            if (!actionListId) {
                console.log("ActionList ID not found.");
                return;
            }
            try {

                const response = await fetch(config.API_URI + `/api/actionLists/${actionListId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });


                if (!response.ok) {
                    setIsValid(false);
                    throw new Error('Failed to fetch card details');
                }
                const data = await response.json();

                _setBoardId(data.actionList.board);

                if (data.actionList.board) {
                    const responseData = await fetch(config.API_URI + `/api/boards/${data.actionList.board}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (!responseData.ok) {
                        throw new Error('Failed to fetch board details');
                    }
                    const dataBoard = await responseData.json();
                    // setActionLists(dataBoard.actionLists)
                    _setBoardSlug(toSlug(dataBoard.title));

                    const listTitle = dataBoard?.actionLists?.filter(actionList => {
                        return (actionList._id === actionListId);
                    });

                    setActionListTitle(listTitle[0]?.title);

                }
            } catch (error) {
                console.error('Error fetching card details:', error);
            }
        };

        fetchBoardData();
    }, [actionListId]);


    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '24px'; // Reset to auto height initially
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`; // Adjust height based on content

            // Ensure each line height is 24px
            const lineCount = Math.ceil(textAreaRef.current.scrollHeight / 24);
            textAreaRef.current.style.height = `${(lineCount * 24) - 24}px`;
        }
    }, [title]);


    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const length = quill.getLength(); // Get the length of the content
            quill.setSelection(length - 1, 0); // Set the cursor at the end
            quill.focus(); // Focus the editor
        }
    }, [isEditingDescription]);



    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent new line creation on Enter key press
        }
    };

    const handleUpdateCard = async () => {
        const token = localStorage.getItem('accessToken');
        const sanitizedHtmlDes = sanitizeHtml(description);

        try {
            const response = await fetch(config.API_URI + `/api/cards/update/${cardId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ title, description: sanitizedHtmlDes })
            });

            if (!response.ok) {
                throw new Error('Failed to update card');
            }

            const data = await response.json();
            setCardDetails(data.card);
            setIsModified(false); // Reset modification state
        } catch (error) {
            console.error('Error updating card:', error);
        }
    };

    const handleArchiveCard = async () => {
        const token = localStorage.getItem('accessToken');

        try {
            const response = await fetch(config.API_URI + `/api/cards/archive/${cardId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to archive the card');
            }

            const data = await response.json();
            console.log('Card archived successfully:', data);
        } catch (error) {
            console.error('Error archiving the card:', error);
        }
    };

    const handleCloseCardPopup = (event) => {
        if (event.target.classList.contains('zoobbe-card-modal-container') || event.target.closest('.close-card')) {
            navigate(boardPermalink);
        }
    };

    if (!isValid) {
        return <NotFound />;
    }

    return (
        <div className="zoobbe-card-wrapper">
            <div className='zoobbe-card-modal-container' onClick={handleCloseCardPopup}>
                <div className="zoobbe-card-details">
                    <div className="zoobbe-card-content">
                        <div className="zoobbe-card-details-body">
                            <div className="zoobbe-card-details-header">
                                <div className='card-heading'>
                                    <IconCardTitle />
                                    <textarea
                                        ref={textAreaRef}
                                        className="zoobbe-card-title-textarea"
                                        value={title}
                                        onChange={(e) => {
                                            setTitle(e.target.value);
                                            setIsModified(true);
                                        }}
                                        onBlur={handleUpdateCard}
                                    />
                                    <div className="close-card-container">
                                        <div className='close-card'>
                                            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="zoobbe-card-details-status">in list <span>{actionListTitle}</span></div>
                            </div>
                            <div className="zoobbe-card-details-info">
                                <div className='zoobbe-card-details-info-left'>
                                    <div className='card-details-top'>
                                        <CardMembers cardId={cardId} />
                                        <CardLabels cardId={cardId} />
                                        <div className="zoobbe-notifications">
                                            <h5>Notifications</h5>
                                            <div className='notifications-content'>
                                                <span class="material-icons-outlined">visibility</span>
                                                <span className="zoobbe-watching">Watch</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`zoobbe-description ${isEditingDescription ? 'currently-editing' : ''}`}>
                                        <h3>
                                            <IconDescription />
                                            Description
                                        </h3>
                                        {!description && !isEditingDescription && (
                                            <div className="zoobbe-card-no-description" onClick={() => setIsEditingDescription(true)}>Add a more detailed description of the task here... </div>
                                        )}
                                        {!isEditingDescription && description && (
                                            <div className="card-description-content quill" onClick={() => setIsEditingDescription(true)} dangerouslySetInnerHTML={{ __html: description }} />
                                        )}
                                        {isEditingDescription && card && (
                                            <>

                                                <Editor
                                                    cardId={cardId}
                                                    ref={quillRef}
                                                    value={description}
                                                    onChange={(value) => {
                                                        setDescription(sanitizeHtml(value));
                                                        setIsModified(true);
                                                    }}
                                                />

                                                <div className='editor-action-buttons'>
                                                    <button className='save-editing-content' onClick={() => { handleUpdateCard(); setIsEditingDescription(false); }}>Save</button>
                                                    <button className='cancel-editing-content' onClick={() => setIsEditingDescription(false)}>Discard</button>
                                                </div>
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileUpload}
                                                />
                                            </>
                                        )}
                                    </div>

                                    {
                                        attachments.length > 0 && (
                                            <Attachments cardId={cardId} />
                                        )
                                    }

                                    <Checklist cardId={cardId} />
                                    <div className="zoobbe-card-details-footer">
                                        <Comments cardId={cardId} />
                                        <div className="zoobbe-activity">
                                            <p>Akash Mia moved this card from To-Do to Done / Fixed<br />Apr 29 at 12:46 PM</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='zoobbe-card-details-info-right'>
                                    <CardDetailsSidebar cardId={cardId} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="space-bottom"></div>
                </div>
                {/* <PopOver /> */}
            </div>
        </div>
    );

};

export default CardDetails;
