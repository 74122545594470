import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getChecklistStatus, toSlug } from '../../utils/helpers';
import { fetchCardMembers } from '../../redux/Slices/cardMemberSlice';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import MemberImage from '../Global/MemberImage';
import { fetchLabels } from '../../redux/Slices/labelSlice';

const Card = (card) => {

  const { title, _id } = card;

  const cardSlug = toSlug(title);

  const dispatch = useDispatch();
  const { labelsByCard, status, error } = useSelector((state) => state.labels);
  const labels = labelsByCard[_id] || [];

  useEffect(() => {
    if (_id && labels.length === 0) {
      dispatch(fetchLabels({ _id }));
    }
  }, [_id, labels.length, dispatch]);

  const { membersByCard } = useSelector((state) => state.cardMembers);
  const members = membersByCard[_id] || [];


  useEffect(() => {
    if (_id && members.length === 0) {
      dispatch(fetchCardMembers(_id));
    }
  }, [dispatch, _id, members.length]);

  const checklistsStatus = getChecklistStatus(card.checklists);



  return (
    <div className='card-pemalink-wrapper'>
      <div className={`zoobbe-card`} >

        <div className='card-header'>

          {
            labels.length > 0 && (
              <div className='card-labels'>
                {labels?.map(label => (
                  <span key={label._id} style={{ backgroundColor: label.color }} className={`label ${toSlug(label.text)}-label`}></span>
                ))}
              </div>
            )
          }
          <span className="card-option-icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4m7 0a2 2 0 1 0 0-4 2 2 0 0 0 0 4m9-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
                fill="currentColor"
              />
            </svg>
          </span>

        </div>

        <p>{title}</p>
        <div className="zoobbe-card-footer">
          <div className="zoobbe-card-front-badges">
            <div className="zoobbe-card-front-badges">

              <span className="badge-card-subscribe badge-icon">
                <span class="material-icons-outlined">
                  visibility
                </span>
              </span>

              {
                card.description && (
                  <span className="badge-card-description badge-icon">
                    <span class="material-icons-outlined">
                      reorder
                    </span>
                  </span>
                )
              }
              {
                card.comments.length > 0 && (
                  <span className="badge-card-comments badge-icon">
                    <span class="material-icons-outlined">
                      mode_comment
                    </span>
                    <span className='comments-count count-number'>{card.comments.length}</span>
                  </span>
                )
              }

              {
                checklistsStatus[1] > 0 && (
                  <span className="badge-card-checklists badge-icon">
                    <span class="material-icons">task_alt</span>
                    <span className='checklists-count count-number'>{checklistsStatus[0]}/{checklistsStatus[1]}</span>
                  </span>
                )
              }

              {
                card.attachments.length > 0 && (
                  <span className="badge-card-attachments badge-icon">
                    <span class="material-icons">
                      attach_file
                    </span>
                    <span className='attachment-count count-number'>{card.attachments.length}</span>
                  </span>
                )
              }
            </div>

          </div>
          <div className="avatars">
            <MemberImage members={members} size={28} />
          </div>
        </div>
      </div>
      <Link to={`/c/${_id}/${cardSlug}`} className='card-pemalink'></Link>
    </div>
  );
};

export default Card;
