import React, { useState } from 'react';

const ZoobbeSelect = ({ options, defaultSelectedOption, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(options[defaultSelectedOption]);

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        onSelect(option);
    };

    return (
        <div className="zoobbe-select">
            <div className={`zoobbe-select-trigger ${isOpen && ' active'}`} onClick={() => setIsOpen(!isOpen)}>
                {selectedOption?.label}
                {/* {
                    isOpen ? (
                        <span className="arrow-up">∟</span>
                    ) : (
                        <span className="arrow-down">∟</span>
                    )
                } */}

                <span className="arrow-down">∟</span>
            </div>
            {isOpen && (
                <ul className="zoobbe-select-options">
                    {options.map((option, index) => (
                        <li key={option?.value} onClick={() => handleOptionClick(option)} className={`zoobbe-select-option${selectedOption.value === option.value ? ' selected' : ''}`}>
                            {option?.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ZoobbeSelect;
