// contentRegistry.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AddChecklist from '../../components/WindowSidebar/AddChecklist';
import AddLabels from '../../components/WindowSidebar/AddLabels';
import ProfileCard from '../../components/Global/ProfileCard';

const popover = {
    addChecklist: <AddChecklist />,
    addLabels: <AddLabels />,
    memberInfo: <ProfileCard />,
    // Add more mappings as needed
};

export const getContentById = (id) => popover[id] || null;
