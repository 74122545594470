import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './scss/index.scss';
import { toSlug } from '../../utils/helpers';
import { config } from '../../config';

const Workspaces = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [boards, setBoards] = useState([]);
  const [showFormForWorkspace, setShowFormForWorkspace] = useState(null);
  const [boardTitle, setBoardTitle] = useState('');
  // const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const fetchActionLists = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(config.API_URI  + `/api/boards`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          // setIsValid(false);
          throw new Error('Failed to fetch boards');
        }

        const data = await response.json();
        setBoards(data);
      } catch (error) {
        console.error('Error fetching boards:', error);
      }
    };

    fetchActionLists();
  }, []);

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(config.API_URI + '/api/workspaces/me', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch workspaces');
        }

        const data = await response.json();

        // Set the state with the fetched data if it returns successfully
        setWorkspaces(data);
      } catch (error) {
        console.error('Error fetching workspaces:', error);
      }
    };

    fetchWorkspaces();
  }, []);

  const handleAddButton = (workspaceId) => {
    setShowFormForWorkspace(prevWorkspaceId => prevWorkspaceId === workspaceId ? null : workspaceId);
  };

  const handleAddBoard = async (e, workspaceId) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('accessToken');
      const response = await fetch(config.API_URI  + `/api/boards`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          workspaceId: workspaceId,
          title: boardTitle,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to add action list');
      }

      const data = await response.json();
      const newBoard = data.board;
      setBoards([...boards, newBoard]);

      // Update the workspace with the new board
      setWorkspaces(prevWorkspaces => prevWorkspaces.map(workspace => {
        if (workspace._id === workspaceId) {
          return { ...workspace, boards: [...workspace.boards, newBoard] };
        }
        return workspace;
      }));

      setBoardTitle('');
      setShowFormForWorkspace(null);
    } catch (error) {
      console.error('Error adding board:', error);
    }
  };

  const handleHideForm = () => {
    setShowFormForWorkspace(null);
  };

  console.log(workspaces);

  return (
    <div className='zoobbe-workspace-container'>
      <h1>Your Workspaces</h1>
      {workspaces.map(workspace => (
        <div key={workspace._id} className='workspace'>
          <div className='workspace-header'>
            <div className='workspace-icon'>{workspace.name ? workspace.name.charAt(0) : ''}</div>
            <h2>{workspace.name}</h2>
            <div className='workspace-actions'>
              <Link to={'/w/wpdeveloper5186/Boards'} >Boards</Link>
              <Link to={'/w/wpdeveloper5186/Views'} >Views</Link>
              <Link to={'/w/wpdeveloper5186/Members'} >Members</Link>
              <Link to={'/w/wpdeveloper5186/Settings'} >Settings</Link>
              <Link to={'/w/wpdeveloper5186/Upgrade'} >Upgrade</Link>
            </div>
          </div>
          <div className='board-container'>
            {workspace.boards.map(board => (
              <div key={board._id} className='board'>
                <Link to={`/b/${board._id}/${toSlug(board.title)}`} className='board-link'>
                  {board.title}
                </Link>
              </div>
            ))}
          </div>

          {showFormForWorkspace === workspace._id ? (
            <form onSubmit={(e) => handleAddBoard(e, workspace._id)} className='board-form'>
              <textarea
                name="add-new-board"
                placeholder="Enter Board Title"
                value={boardTitle}
                onChange={(e) => setBoardTitle(e.target.value)}
              />
              <div className='footer-buttons'>
                <button type="submit" className='add-btn'>Add</button>
                <button type="button" onClick={handleHideForm}>
                  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10.586 12 5.293 6.707a1 1 0 0 1 1.414-1.414L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414z" fill="#000"></path></svg>
                </button>
              </div>
            </form>
          ) : (
            <div className='create-button-container'>
              <button onClick={() => handleAddButton(workspace._id)} className='add-board-btn'>
                Create new board
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Workspaces;
