// utils/helpers.js

import { fetchWorkspaces } from '../redux/Slices/workspaceSlice';
import { config } from '../config';

const shortUUID = require('short-uuid');

// utils/helpers.js
export const getUser = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        return false;
    }

    try {
        const response = await fetch(config.API_URI + '/api/users/me', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Invalid token');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error validating token:', error);
        return false;
    }
};

export const getUserById = async (userId) => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        return false;
    }

    try {
        const response = await fetch(config.API_URI + `/api/users/${userId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Invalid token');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error validating token:', error);
        return false;
    }
};
export const getUsers = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        return false;
    }

    try {
        const response = await fetch(config.API_URI + '/api/users/all-users', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Invalid token');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('Error validating token:', error);
        return false;
    }
};


// Function to refresh the access token
export const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
        return false;
    }

    try {
        const response = await fetch(config.API_URI + '/api/users/refresh-token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: refreshToken }),
        });

        const data = await response.json();
        if (data.accessToken) {
            localStorage.setItem('accessToken', data.accessToken);
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error refreshing token:', error);
        return false;
    }
};


export const toSlug = (title) => {
    const slug = title?.toLowerCase().replace(/\W+/g, '-');

    return slug?.replace(/^-+/, '').replace(/-+$/, '');
}


const translator = shortUUID();

export const maskId = (id) => {
    return translator.fromUUID(id);
};

export const unmaskId = (maskedId) => {
    return translator.toUUID(maskedId);
};


export const sanitizeHtml = (html) => {
    // Regex to match <pre> tags and their content
    const preRegex = /<pre.*?>([\s\S]*?)<\/pre>/gi;

    // Replace content within <pre> tags
    let sanitizedHtml = html.replace(preRegex, (match, content) => {
        const cleanedContent = content.replace(/\s+/g, ' ');
        return `<pre>${cleanedContent}</pre>`;
    });

    // Strip out all HTML tags, <br> tags, and whitespace
    const strippedContent = sanitizedHtml.replace(/<\/?[^>]+(>|$)|\s|<br\s*\/?>/gi, '');

    // If the stripped content is empty, return an empty string; otherwise, return the sanitized HTML
    return strippedContent === '' ? '' : sanitizedHtml;
};


export const addMember = async ({ cardId, memberId }) => {
    const token = localStorage.getItem('accessToken');
    try {
        const response = await fetch(config.API_URI + `/api/cards/${cardId}/addMember`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ memberId })
        });

        if (!response.ok) {
            throw new Error('Failed to add member');
        }

        const data = await response.json();

        return { cardId, member: data }
        // return data;
    } catch (error) {
        console.error('Error adding member:', error);
        throw error;
    }
};
export const uploadFile = async (event, cardId) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
        const token = localStorage.getItem('accessToken');
        const response = await fetch(config.API_URI + `/api/cards/attachments/${cardId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: formData
        });

        if (!response.ok) {
            throw new Error('Failed to upload file');
        }

        const attachments = await response.json();
        console.log({ attachments });
        return attachments;
    } catch (error) {
        console.error('Error uploading file:', error);
    }
};

export const timeAgo = (datetime) => {
    const now = new Date();
    const date = new Date(datetime);
    const diffInMs = now - date;

    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInMonths = Math.floor(diffInDays / 30);
    const diffInYears = Math.floor(diffInDays / 365);

    if (diffInYears > 0) {
        return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`;
    } else if (diffInMonths > 0) {
        return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;
    } else if (diffInDays > 0) {
        return date.toDateString(); // Return actual date if more than a day
    } else if (diffInHours > 0) {
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    } else if (diffInMinutes > 0) {
        return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
    } else if (diffInSeconds > 0) {
        return `${diffInSeconds} second${diffInSeconds > 1 ? 's' : ''} ago`;
    } else {
        return 'just now';
    }
}

export const getFileExtension = (filename) => {
    if (!filename) return '';
    const lastDotIndex = filename.lastIndexOf('.');
    return lastDotIndex !== -1 ? filename.substring(lastDotIndex + 1) : '';
};


const lookupFunctions = {
    workspace: (data, { id }) => data.find(ws => ws._id === id) || null,

    boards: (data, { workspace }) => {
        const ws = data.find(ws => ws._id === workspace);
        return ws ? ws.boards : [];
    },

    board: (data, { id }) => {
        const ws = data.find(ws => ws.boards.some(b => b._id === id));
        return ws ? ws.boards.find(b => b._id === id) : null;
    },

    cards: (data, { board, actionlist }) => {
        if (board) {
            const ws = data.find(ws => ws.boards.some(b => b._id === board));
            return ws ? ws.boards.find(b => b._id === board).actionLists.flatMap(al => al.cards) : [];
        }
        if (actionlist) {
            const ws = data.find(ws => ws.boards.some(b => b.actionLists.some(al => al._id === actionlist)));
            return ws ? ws.boards.flatMap(b => b.actionLists.find(al => al._id === actionlist).cards) : [];
        }
        return [];
    },

    card: (data, { id }) => {
        const ws = data.find(ws =>
            ws.boards.some(b => b.actionLists.some(al => al.cards.some(c => c._id === id)))
        );
        return ws ? ws.boards
            .flatMap(b => b.actionLists)
            .flatMap(al => al.cards)
            .find(c => c._id === id) : null;
    },

    members: (data, { workspace, board }) => {
        if (workspace) {
            const ws = data.find(ws => ws._id === workspace);
            return ws ? ws.members : [];
        }
        if (board) {
            const ws = data.find(ws => ws.boards.some(b => b._id === board));
            return ws ? ws.boards.find(b => b._id === board).members : [];
        }
        return [];
    },

    users: (data, { card }) => {
        const ws = data.find(ws =>
            ws.boards.some(b => b.actionLists.some(al => al.cards.some(c => c._id === card)))
        );
        return ws ? ws.boards
            .flatMap(b => b.actionLists)
            .flatMap(al => al.cards)
            .find(c => c._id === card).users : [];
    }
};

export const find = {
    get: (data, params) => {
        const lookup = lookupFunctions[params.name];
        if (lookup) {
            return lookup(data, params);
        }
        return [];
    }
};

// utils/getScrollableAncestor.js
export const getScrollableAncestor = (element) => {
    let currentElement = element;

    while (currentElement) {
        if (currentElement === document.body) {
            return window;
        }
        const overflowY = window.getComputedStyle(currentElement).overflowY;
        if (overflowY === 'scroll' || overflowY === 'auto') {
            return currentElement;
        }
        currentElement = currentElement.parentElement;
    }

    return window;
};


export const getChecklistStatus = (checklists) => {
    let totalItems = 0;
    let checkedItems = 0;

    checklists && checklists.forEach(checklist => {
        totalItems += checklist.items.length;
        checkedItems += checklist.items.filter(item => item.checked).length;
    });

    return [checkedItems, totalItems];
}
