import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../redux/Slices/modalSlice';
import { selectBoardId } from '../../redux/Slices/boardIdSlice';
import { fetchBoardMembers } from '../../redux/Slices/boardMembersSlice';
import { togglePopover } from '../../redux/Slices/popoverSlice';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import MemberImage from '../Global/MemberImage';
import { find } from '../../utils/helpers';


const Navbar = () => {
    const dispatch = useDispatch();
    const boardId = useSelector(selectBoardId);

    const { workspaces } = useSelector((state) => state.workspaces);

    const boardData = find.get(workspaces, { name: 'board', id: boardId });


    const { members, loading: membersLoading, error: membersError } = useSelector((state) => state.boardMembers);

    const handleShareBoardModal = () => {
        dispatch(openModal({ modalType: 'SHARE_BOARD', modalData: {} }));
    };

    useEffect(() => {
        if (boardId && members.length === 0) {
            dispatch(fetchBoardMembers(boardId));
        }
    }, [dispatch, boardId, members.length]);

    const handleMemberInfo = (e, contentId) => {
        console.log(e, contentId);

        const target = e.currentTarget;
        const rect = target.getBoundingClientRect();
        const position = { top: rect.bottom, left: rect.left };
        dispatch(togglePopover({ contentId, position, target }));
    };


    return (
        <div className="zoobbe-workspace-navbar">
            <div className="zoobbe-navbar-left">
                <span className="zoobbe-workspace-name">{boardData?.title}</span>
                <span className="zoobbe-star">★</span>
                <span className="zoobbe-workspace">Workspace visible</span>
                <button className="zoobbe-board-button">Board</button>
            </div>
            <div className="zoobbe-navbar-right">
                <button className="zoobbe-power-ups">Power-Ups</button>
                <button className="zoobbe-filters">Filters</button>
                <button className="zoobbe-clear">Clear all</button>
                <div className="zoobbe-members">
                    <MemberImage members={members} size={30} />

                    {
                        members.length > 3 && (
                            <span className="zoobbe-more-members">+{members.length - 3}</span>
                        )
                    }
                </div>
                <button className="zoobbe-share" onClick={handleShareBoardModal}>Share</button>
            </div>
        </div>
    );
};

export default Navbar;
