import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './scss/Comments.scss';
import ImagePlaceholder from '../Global/ImagePlaceholder';
import ReactQuill from 'react-quill';
import { sanitizeHtml, timeAgo } from '../../utils/helpers';
import { config } from '../../config';
import Editor from '../Editor/Editor';

const Comments = ({ cardId }) => {
    const { user, loading: userLoading, error: userError } = useSelector((state) => state.user);

    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editingComment, setEditingComment] = useState('');
    const [isAddComment, setAddComment] = useState('');
    const [isCommentBoxClicked, setCommentBoxClicked] = useState(true);
    const quillRef = useRef(null);

    useEffect(() => {
        fetchComments();
    }, [cardId]);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            const length = quill.getLength(); // Get the length of the content

            if (isCommentBoxClicked) {
                quill.setSelection(length - 1, 0); // Set the cursor at the end
                setCommentBoxClicked(false)
            }

            quill.focus(); // Focus the editor
        }
    }, [editingComment, isAddComment, isCommentBoxClicked]);

    const fetchComments = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/comments`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch comments');
            }

            const data = await response.json();
            setComments(data);
        } catch (error) {
            console.error('Error fetching comments:', error);
        }
    };

    const handleAddComment = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/comments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ comment: newComment, member: user?.user?._id, reacts: [] })
            });

            if (!response.ok) {
                throw new Error('Failed to add comment');
            }

            const data = await response.json();
            setComments([data.comments[data.comments.length - 1], ...comments]);
            setNewComment('');
            setAddComment(false)
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

    const handleEditComment = (comment) => {
        setEditingCommentId(comment._id);
        setEditingComment(comment.comment);
        setCommentBoxClicked(true);
    };

    const handleUpdateComment = async (e, commentId) => {
        e.preventDefault();
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/comments/${commentId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ comment: editingComment })
            });

            if (!response.ok) {
                throw new Error('Failed to update comment');
            }

            const updatedCard = await response.json();
            console.log(updatedCard);

            // Update comments state immutably
            setComments(updatedCard.comments); // Assuming updatedCard.comments directly contains the updated comments array

            setEditingCommentId(null);
            setEditingComment('');
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    };

    const handleDeleteComment = async (commentId) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/comments/${commentId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to delete comment');
            }

            setComments(comments.filter(comment => comment._id !== commentId));
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    return (
        <div className="comment-section">
            <div className="comment">
                <img src={user?.user.profilePicture} alt="{user?.user.username}" className="comment__profile-img" />
                <div className={`comment__content comment-editor-field`}>
                    <div className="comment__actions">
                        {
                            isAddComment ? (
                                <>
                                    <Editor
                                        cardId={cardId}
                                        ref={quillRef}
                                        value={newComment}
                                        onChange={(value) => { setNewComment(sanitizeHtml(value)); }}
                                    />
                                    <div className='editor-action-buttons'>
                                        <button className='save-editing-content' onClick={(e) => { handleAddComment(e); }}>Save</button>
                                        <button className='cancel-editing-content' onClick={() => { setAddComment(false) }}>Discard</button>
                                    </div>
                                </>
                            ) : (
                                <div className="card-comment-content write-comment-field" onClick={() => setAddComment(true)}>
                                    Write a comment...
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

            {comments.map(comment => (
                <div key={comment._id} className="comment">
                    {comment.member?.profilePicture ? (
                        <img src={comment.member.profilePicture} alt={comment.member?.username} className="comment__profile-img" />
                    ) : (
                        <ImagePlaceholder text={comment.member?.username} />
                    )}
                    <div className="comment__content">
                        <div className="comment__header">
                            <strong>{comment.member?.username}</strong>
                            <span>{timeAgo(comment.time)}</span>
                        </div>
                        {editingCommentId === comment._id ? (
                            <>

                                <Editor
                                    cardId={cardId}
                                    ref={quillRef}
                                    value={editingComment}
                                    onChange={(value) => setEditingComment(sanitizeHtml(value))}

                                />

                                <div className='editor-action-buttons'>
                                    <button className='save-editing-content' onClick={(e) => handleUpdateComment(e, comment._id)}>Update</button>
                                    <button className='cancel-editing-content' onClick={() => setEditingCommentId(null)}>Discard Changes</button>
                                </div>
                            </>
                        ) : (
                            <div className="card-comment-content" dangerouslySetInnerHTML={{ __html: comment.comment }} />
                        )}

                        <div className="comment__actions">
                            <a href="#" className="comment__action-link" onClick={() => handleEditComment(comment)}>Edit</a>
                            <a href="#" className="comment__action-link" onClick={() => handleDeleteComment(comment._id)}>Delete</a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Comments;
