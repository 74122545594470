import React from 'react';
import './scss/index.scss';

const AddCardButton = ({onClick}) => {
    return (
        <div className="card-button-container">
            <button className="add-card-button" onClick={onClick}>
                <span className="plus-icon">+</span>
                Add a card
            </button>
            <span className='card-icon'>
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 6V5a2 2 0 0 1 2-2h1a1 1 0 0 1 0 2H5v1a1 1 0 0 1-2 0" fill="currentColor" /><path fillRule="evenodd" clipRule="evenodd" d="M6 8a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2zm2 0h11v6H8zm10 10a1 1 0 1 1 0-2 1 1 0 0 1 0 2M8 17a1 1 0 0 0 1 1h3a1 1 0 1 0 0-2H9a1 1 0 0 0-1 1" fill="currentColor" /><path d="M4 14a1 1 0 0 0-1 1v1a2 2 0 0 0 2 2v-3a1 1 0 0 0-1-1M3 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0zm5-5a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1m8-1a1 1 0 1 0 0 2h3a2 2 0 0 0-2-2z" fill="currentColor" /></svg>
            </span>
        </div>
    );
}

export default AddCardButton;
