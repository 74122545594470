// src/redux/slices/checklistSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { config } from '../../config';

// Async actions
export const fetchChecklists = createAsyncThunk(
    'checklists/fetchChecklists',
    async (cardId, { getState, rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/checklists`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            return data.checklists;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const addChecklistItem = createAsyncThunk(
    'checklists/addChecklistItem',
    async ({ cardId, checklistId, newItem }, { getState, rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/checklists/${checklistId}/items`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ title: newItem, checked: false })
            });
            const data = await response.json();
            return data.checklists;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateChecklistItem = createAsyncThunk(
    'checklists/updateChecklistItem',
    async ({ cardId, checklistId, itemId, title, checked }, { getState, rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            const response = await fetch(config.API_URI + `/api/cards/${cardId}/checklists/${checklistId}/items/${itemId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ title, checked })
            });
            const data = await response.json();
            return data.checklists;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteChecklistItem = createAsyncThunk(
    'checklists/deleteChecklistItem',
    async ({ cardId, checklistId, itemId }, { getState, rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            await fetch(config.API_URI + `/api/cards/${cardId}/checklists/${checklistId}/items/${itemId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            return { checklistId, itemId };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteChecklist = createAsyncThunk(
    'checklists/deleteChecklist',
    async ({ cardId, checklistId }, { getState, rejectWithValue }) => {
        try {
            const token = localStorage.getItem('accessToken');
            await fetch(config.API_URI + `/api/cards/${cardId}/checklists/${checklistId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            return { checklistId };
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const checklistSlice = createSlice({
    name: 'checklists',
    initialState: {
        checklists: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChecklists.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchChecklists.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.checklists = action.payload;
            })
            .addCase(fetchChecklists.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(addChecklistItem.fulfilled, (state, action) => {
                state.checklists = action.payload;
            })
            .addCase(updateChecklistItem.fulfilled, (state, action) => {
                state.checklists = action.payload;
            })
            .addCase(deleteChecklistItem.fulfilled, (state, action) => {
                const { checklistId, itemId } = action.payload;
                state.checklists = state.checklists.map(checklist =>
                    checklist._id === checklistId
                        ? { ...checklist, items: checklist.items.filter(item => item._id !== itemId) }
                        : checklist
                );
            })
            .addCase(deleteChecklist.fulfilled, (state, action) => {
                const { checklistId } = action.payload;
                state.checklists = state.checklists.filter(checklist => checklist._id !== checklistId);
            });
    }
});

export default checklistSlice.reducer;
