import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toSlug } from '../../utils/helpers';

import { openModal } from '../../redux/Slices/modalSlice';
import { config } from '../../config';

import CreateWorkspace from './CreateWorkspace';

import { fetchWorkspaces } from '../../redux/Slices/workspaceSlice';

import WorkspaceSidebar from './WorkspaceSidebar';

import './scss/workspace.scss';

import './scss/Boards.scss';


import BoardSection from './BoardSection';

const Boards = () => {

    const [boards, setBoards] = useState([]);
    const [showFormForWorkspace, setShowFormForWorkspace] = useState(null);
    const [boardTitle, setBoardTitle] = useState('');
    const { workspaces, status, error } = useSelector(state => state.workspaces);

    const { workspaceSlug } = useParams();



    const dispatch = useDispatch();

    const handleCreateWorkspace = () => {
        dispatch(openModal({ modalType: 'CREATE_WORKSPACE', modalData: {} }));
    };

    const handleCreateBoard = (defaultSelectedOption) => {
        dispatch(openModal({ modalType: 'CREATE_BOARD', modalData: { defaultSelectedOption } }));
    };


    // useEffect(() => {
    //     if (!workspaces) {
    //         dispatch(fetchWorkspaces());
    //     }
    // }, [dispatch, workspaces])

    // useEffect(() => {
    //     dispatch(fetchWorkspaces());
    // }, [dispatch]);



    const [openWorkspaces, setOpenWorkspaces] = useState({});

    useEffect(() => {
        const initialOpenWorkspaces = {};
        workspaces.slice(0, 2).forEach(workspace => {
            initialOpenWorkspaces[workspace.name] = true;
        });
        setOpenWorkspaces(initialOpenWorkspaces);
    }, [workspaces]);

    const toggleOptions = (workspaceName) => {
        setOpenWorkspaces(prevState => ({
            ...prevState,
            [workspaceName]: !prevState[workspaceName]
        }));
    };

    useEffect(() => {
        const fetchActionLists = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await fetch(config.API_URI  + `/api/boards`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    // setIsValid(false);
                    throw new Error('Failed to fetch boards');
                }

                const data = await response.json();
                setBoards(data);
            } catch (error) {
                console.error('Error fetching boards:', error);
            }
        };

        fetchActionLists();
    }, []);

    const handleAddButton = (workspaceId) => {
        setShowFormForWorkspace(prevWorkspaceId => prevWorkspaceId === workspaceId ? null : workspaceId);
    };

    const handleHideForm = () => {
        setShowFormForWorkspace(null);
    };

    return (
        <div className="zppbbe-workspace-wrapper">
            <div className="zoobbe-workspace-container">
                <WorkspaceSidebar
                    openWorkspaces={openWorkspaces}
                    toggleOptions={toggleOptions}
                    handleCreateWorkspace={handleCreateWorkspace}
                />

                <main className="zoobbe-main-content">
                    <div className="board-header">
                        <div className="board-icon">
                            <span className="icon-text">Z</span>
                        </div>
                        <div className="board-details">
                            <div className="board-name">
                                <span>Zoobbe</span>
                                <svg className="edit-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 0-.765-.638l-1.75 1.75a.5.5 0 1 0 .707.707l1.75-1.75a.5.5 0 0 0 .058-.069zM1 13.5V16h2.5l7.157-7.157-2.5-2.5L1 13.5zm3.207-1.207-2.5 2.5-.793-.793 2.5-2.5L4.207 12.5z" />
                                </svg>
                            </div>
                            <div className="board-visibility">
                                <svg className="lock-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8 1a4 4 0 0 0-4 4v3h8V5a4 4 0 0 0-4-4zm-3 5V5a3 3 0 1 1 6 0v1H5z" />
                                    <path d="M1 9v7a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9H1zm1 2h12v4H2v-4z" />
                                </svg>
                                <span>Private</span>
                            </div>
                        </div>
                    </div>

                    <BoardSection boards={[]} defaultSelectedOption={0} />
                </main>
            </div>
        </div>
    );
};

export default Boards;

