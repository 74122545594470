// cardSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    card: null,
};

const cardSlice = createSlice({
    name: 'card',
    initialState,
    reducers: {
        cardData: (state, action) => {
            state.card = action.payload;
        },
    },
});

export const { cardData } = cardSlice.actions;
export default cardSlice.reducer;
